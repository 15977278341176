@import "~styles/config.scss";

.currentOrder {
  color: $black;
  .currentOrder-content {
    background-color: $light-grey;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $phone-size) {
      padding: 20px 10px;
    }
    // Title
    .currentOrder-title {
      display: flex;
      justify-content: center;
      padding: 5px 0 5px 0;
      .currentOrder-title-content {
        font-size: 20px;
        font-weight: 600;
      }
    }

    // Edition
    .currentOrder-edition {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 30px;
      .currentOrder-edition-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        .currentOrder-edition-content-label {
          font-weight: $semi-bold;
          text-align: left;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
        .currentOrder-edition-content-edition {
          text-align: right;
          transform: (translateY(3px));
          font-size: 14px;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
      }
    }

    // Dates
    .currentOrder-dates {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 20px;
      .currentOrder-dates-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        .currentOrder-dates-content-label {
          font-weight: $semi-bold;
          text-align: left;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
        .currentOrder-dates-content-dates {
          text-align: right;
          text-transform: uppercase;
          transform: (translateY(3px));
          font-size: 14px;
          line-height: 20px;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
      }
    }

    // Format
    .currentOrder-format {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 20px;
      .currentOrder-format-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        .currentOrder-format-content-label {
          font-weight: $semi-bold;
          text-align: left;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
        .currentOrder-format-content-format {
          text-align: right;
          transform: (translateY(3px));
          font-size: 14px;
          text-transform: capitalize;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
      }
    }

    // Price
    .currentOrder-price {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 40px;
      margin-bottom: 20px;
      .currentOrder-price-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-weight: $semi-bold;
        border-top: 1px solid $black;
        padding-top: 20px;
        .currentOrder-format-price-label {
          text-align: left;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
        .currentOrder-format-price-format {
          text-align: right;
          transform: (translateY(3px));
          font-size: 14px;
          @media (max-width: $phone-size) {
            font-size: 0.8rem;
          }
        }
      }
    }

    div.currentOrder-price--ttc {
      margin-top: 0;
      flex-direction: column;
      .currentOrder-price-content {
        margin: 0 auto;
      }
    }

    // Button
    .currentOrder-button {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 5px 0 5px 0;
      margin-top: 20px;
      .currentOrder-button-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        .button-container {
          width: 100%;
          display: flex;
        }
        &.multi {
          justify-content: space-around;
        }
        .button {
          flex: 1 1 auto;
        }
        .spacer {
          width: 5px;
        }
      }
    }
  }

  // ERROR
  .currentOrder-error {
    margin-top: 5px;
    background-color: $pink;
    .currentOrder-error-content {
      .currentOrder-error-content-message {
        padding: 10px;
        color: $black;
        text-align: left;
        font-size: 12px;
      }
    }
  }
}
