@import '~styles/config.scss';

.container{
    &__noorder{
        margin-bottom: 20px;
    }
    &__item{
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        &:last-of-type{
            width: 100%;
        }
        @media (max-width: $phone-size) {
            width: 100%;
            font-size: 0.8rem;
            margin-bottom: 10px;
        }
        b{
            margin-bottom: 10px;
        }
        ul{
            list-style: none;
            padding: 0;
        }
        &__img{
            margin-bottom: 20px;
            font-size: 0.8rem;
            padding: 0;
            text-align: left;
            img{
                margin-bottom: 10px;
            }
        }
        &__pdf{
            width: 100%;
            min-height: 400px;
        }
    }
    &__buttons{
        display: flex;
        button{
            margin-bottom: 20px;
            margin-right: 10px;
        }
    }
}