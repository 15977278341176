@import "~styles/config.scss";

// Normal screen
.header-container {
  height: $header-height;
  flex: 1 1 auto;
  background-color: $company-gray;
  color: $blue;
  @media (max-width: $phone-size) {
    height: $mobile-header-height;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
  }
}

.header-empty-container {
  flex: 1 1 auto;
}
.header-logo-container {
  flex: 1 1 auto;
  height: 70%;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  img {
    height: 100%;
    width: 100%;
  }
}

.header-basket-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  @media (max-width: $tablet-size) {
    margin-right: 15px;
  }
  @media (max-width: $phone-size) {
    margin-right: 0;
  }
  .connection {
    margin-right: 5px;
  }
}
