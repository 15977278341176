@import "~styles/config.scss";

.container {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.431);
  &__content {
    margin: 10px;
    background-color: white;
    opacity: 1;
    position: fixed;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    max-height: 200px;
    left: 0;
    right: 0;
    &__close{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      cursor: pointer;
      outline: none;
      z-index: 100;
    }
  }
}
