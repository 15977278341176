@import "~styles/config.scss";

.testimonial-card-content {
  display: flex;
  align-items: center;
  @media (min-width: $tablet-size+1) {
    flex-direction: row;
    justify-content: center;
    max-width: 65vw;
    margin: 0 auto;
  }
  @media (max-width: $tablet-size) {
    height: auto;
    flex-direction: column;
  }
  .testimonial-card-image-container {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    @media (max-width: $tablet-size) {
      margin-bottom: 20px;
    }
    @media (max-width: $phone-size) {
      height: 75px;
      width: 75px;
      border-radius: 50px;
    }
  }
  .testimonial-card-info-container {
    margin-left: 20px;
    max-width: 55vw;
  }
  .testimonial-card-subtitle {
    opacity: 0.6;
    font-size: 0.8rem;
    margin-top: 2px;
    @media (max-width: $tablet-size) {
      font-size: 0.7rem;
      text-align: center;
    }
    @media (max-width: $phone-size) {
      font-size: 0.7rem;
    }
  }
  .testimonial-card-description {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    text-align: center;
    @media (max-width: $tablet-size) {
      font-size: 0.8rem;
    }
    @media (max-width: $phone-size) {
      font-size: 0.7rem;
    }
    @media (min-width: $tablet-size+1) {
      font-size: 0.9rem;
      text-align: left;
      padding-left: 0px;
      margin-bottom: 10px;
    }
  }
}
