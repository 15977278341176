@import '~styles/config.scss';

.payment-container {
  @media (max-width: $phone-size) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .payment-main {
    display: flex;
    @media (max-width: $phone-size) {
      flex-direction: column;
    }
  }

  .payment-select {
    display: flex;
    padding: 0;
    margin: 0 20px 20px;
    .payment-select-active a {
      background-color: transparent;
      color: black;
    }
    li {
      flex: 1 1 auto;
      text-align: center;
      list-style-type: none;
      &:first-child a {
        margin-left: 0;
      }
      &:last-child a {
        margin-right: 0;
      }
      a {
        background-color: $blue;
        color: white;
        display: block;
        margin: 0 10px;
        padding: 5px 0;
      }
    }
  }

  .payment-forms {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    label{
      font-size: 0.9rem;
      font-weight: $semi-bold;
      letter-spacing: 0.2px;
      margin-bottom: 15px;
    }
  }

  .order-help-container{
    flex: 1 1;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 450px;
  }

  .payment-forms--wrapper {
    position: relative;
    min-height: 300px;
  }

  .loading-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    background-color: white;
  }
}
