@import "~styles/config.scss";

.formatSelection-container {
  flex: 1 1 auto;
  .formatSelection-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    &:hover {
      cursor: pointer;
      transform: scale(1.02, 1.02);
    }
    &.selected {
      transform: scale(1.05, 1.05);
      outline: none;
    }
    .formatSelection-image {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .formatSelection-footer {
      width: 97%;
      background-color: $gray-medium;
      color: white;
      font-size: 1rem;
      font-weight: $semi-bold;
      padding: 5px 10px;
    }
  }
}
