@import "~styles/config.scss";

.text-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  padding: 10px 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  outline: none;
  text-transform: inherit;
  font-size: 1rem;
  font-family: $font-family;
  width: auto;
  &.rounded {
    border-radius: 10px;
  }
  &.fit {
    flex: 1 1 auto;
    width: 100%;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.1s ease-in-out;
  }
  &:active {
    background-color: $pink;
  }
  &.pink {
    background-color: $pink;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    &:active {
      opacity: 0.8;
      background-color: $blue;
    }
  }
  .text-button-text {
    color: white;
    font-weight: $semi-bold;
  }
}
