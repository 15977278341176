@import "~styles/config.scss";

.label {
  display: flex;
  flex-direction: column;
  width: 99%;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 0.9rem;
  font-weight: $semi-bold;
  letter-spacing: 0.2px;
  input,
  textarea{
    background: transparent;
    border: none;
    border-bottom: 1px solid $gray-dark;
    margin-top: 20px;
    outline: none;
  }
  textarea{
    border: 1px solid $gray-dark;
    height: 150px;
  }
  span{
    color: $red-dark;
    border-top: 1px solid $red-dark;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    font-size: 0.8rem;
    padding-top: 10px;
    font-weight: 600;
    margin-top: -1px;
  }
  &:last-child{
    margin-bottom: 0px;
  }
}
