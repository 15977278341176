@import "~styles/config.scss";

.contact-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  .contact-content-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    @media (max-width: $tablet-size) {
      flex-direction: column-reverse;
      margin-bottom: 10px;
    }
    .contact-content-info {
      background: $light-grey;
      padding: 20px;
      color: $black;
      @media (max-width: $tablet-size) {
        text-align: center;
      }
      h6 {
        font-size: 1rem;
        letter-spacing: 0.2px;
        @media (max-width: $tablet-size) {
          margin: 0;
          margin-top: 10px;
        }
      }
      p{
        font-size: 0.9rem;
        &:last-child{
          font-size: 0.8rem;
          margin-top: 40px;
        }
      }
    }
  }
  .contact-form-container {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 50px;
    h6 {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 20px;
      @media (max-width: $phone-size) {
        font-size: 2rem;
      }
    }
    .form-container--row {
      display: flex;
      justify-content: space-between;
      @media (max-width: $tablet-size) {
        flex-direction: column;
      }
      .signUp--input-container {
        width: 48%;
        @media (max-width: $tablet-size) {
          width: unset;
        }
      }
    }
    .text-button-container {
      width: 180px;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
}

.contact-confirm-message{
  text-align: center;
  margin-top: 40px;
  border: 2px solid $blue;
  padding: 30px;
  color: $blue;
  h5{
    margin: 0
  }
}