@import "~styles/config.scss";

.search-input-container {
  .input-container {
    display: flex;
    flex-direction: column;
    input {
      padding: 10px;
      border: 2px solid black;
      // Phone
      @media (max-width: $phone-size) {
        line-height: 22px;
        font-size: 12px;
        border: 1px solid black;
        margin: 0 auto;
      }
      &.text-input-err {
        border: 2px solid red;
        color: $red-dark;
        &::placeholder {
          color: $red-dark;
        }
        &::-ms-input-placeholder {
          color: $red-dark;
        }
      }
      &:focus {
        outline: none;
      }
    }

    .text-input-error-placeholder {
      color: red;
      display: flex;
      align-items: flex-start;
      height: 15px;
      padding-left: 5px;
      // Phone
      @media (max-width: $phone-size) {
        height: 4px;
        justify-content: flex-end;
      }
      .text-input-error {
        display: flex;
        align-items: flex-start;
        height: 100%;
        font-size: 10px;
        // Phone
        @media (max-width: $phone-size) {
          font-size: 8px;
        }
      }
    }
  }
}

// Removes the ugly blue outline and autocomplete background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
