@import '~styles/config.scss';

.container{
    &:nth-child(odd){
        background: $light-grey;
    }
    &__header{
        display: flex;
        list-style: none;
        padding: 15px 10px;
        justify-content: space-between;
        @media (max-width: $phone-size) {
            font-size: 0.8rem;
            flex-wrap: wrap;
        }
        li{
            &:first-child{
                color: $blue;
                font-weight: $semi-bold;
            }
            &:nth-child(2){
                font-weight: $semi-bold;
            }
            &:last-child{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    &__body{
        padding: 15px 10px; 
    }
}