@import "~styles/config.scss";

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  // Phone
  @media (max-width: $phone-size) {
    padding: 0px 10px 0px 10px;
  }
  .loading-container {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // HEADER
  .upload-header {
    position: relative;
    overflow: hidden;
    img.scale {
      width: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      opacity: 0.25;
    }
  }

  // SUBTITLE
  .upload-subtitle {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    width: 100%;
    .upload-subtitle-content {
      font-size: 1.2rem;
      max-width: 600px;
      text-align: center;
      margin-bottom: 50px;
      // Phone
      @media (max-width: $phone-size) {
        flex-direction: column;
        text-align: justify;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

  // Main content
  .upload-main {
    display: flex;
    width: 100%;
    margin-right: 20px;
    @media (max-width: $tablet-size) {
      flex-direction: column;
      margin: 0;
    }

    // SOLUTIONS
    .solutions-container {
      flex: 1 1;

      // Desktop
      @media (min-width: $tablet-size+1) {
        display: flex;
        flex-direction: column;
      }

      // SELECTED SOLUTION
      .solution-type-container {
        height: fit-content;
        background-color: $light-grey;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        outline: none;

        // Tablet & Phone
        @media (max-width: $tablet-size) {
          margin: 0;
          margin-bottom: 20px;
        }

        // HEADER
        .header {
          background-color: $blue;
          padding: 15px 10px;
          outline: none;
          &.selected {
            background-color: $pink;
            outline: none;
          }
          &:hover {
            cursor: pointer;
          }
          color: white;
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: $semi-bold;
          .checkbox {
            margin-right: 5px;
            display: flex;
            align-items: center;
          }
          // Phone
          @media (max-width: $phone-size) {
            font-size: 16px;
          }
        }

        // CONTENT UNDER HEADER
        .solution-type-content {
          flex: 1 1 auto;
          margin-left: 10px;
          margin-right: 10px;
          position: relative;

          .postalCode-search-dropdown-option{
            padding: 5px;
            &:hover {
              cursor: pointer;
              background-color: $pink;
              color: white;
              font-weight: $semi-bold;
            }
            &:active {
              background-color: $pink;
            }
            &.selected {
              background-color: $pink;
            }
          }

          // FILE PICKER CONTAINER
          .file-picker-container {
            width: 100%;
            display: flex;
            align-items: center;
            .file-picker {
              flex: 1 1 auto;
            }
            .icon-container {
              color: white;
              transform: translateY(-8px);
              margin-left: 10px;
            }
          }
        }
      }
    }
    .order-help-container {
      flex: 1 1;
      margin-bottom: 20px;
      max-width: 450px;
      // background-color: red;

      // TABLET AND PHONE
      @media (max-width: $tablet-size) {
        flex: 2;
      }
    }
  }

  // SMALL TEXT AREAS
  .hint {
    width: 100%;
    margin-left: 5px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 0.9rem;
    // Tablet and Phone
    @media (max-width: $tablet-size) {
      font-size: 14px;
    }
    // Phone
    @media (max-width: $phone-size) {
      font-size: 12px;
    }
  }

  //  COMMENT TEXT AREA
  .comment-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  // 'ADD MORE FILES' BUTTON AREA
  .add-file-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .text {
      transform: translateY(-2px);
      margin-left: 10px;
      // Phone
      @media (max-width: $phone-size) {
        font-size: 12px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .icon {
      margin-left: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}