@import "~styles/config.scss";

.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.home-header {
  position: relative;
  overflow: hidden;
  .home-header-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-header-heading {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 10px;
    color: $blue;
    text-align: center;
    @media (max-width: $phone-size) {
      font-size: 2rem;
    }
  }
  svg{
    margin: 25px;
  }
  .home-header-subHeader {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 5px;
    text-align: center;
    line-height: 1.5rem;
    margin-bottom: 20px;
    min-height: 1px;
    @media (max-width: $phone-size) {
      font-size: 1rem;
      line-height: 1rem;
    }
    .home-header-img-container{
      margin: 0 auto;
      margin-top: 10px;
      max-width: 400px;
      width: 40%;
      box-sizing:border-box;
    }
  }
  .home-header-button {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.2rem;
    @media (max-width: $phone-size) {
      font-size: 1rem;
    }
  }
}

.home-description {
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 1200px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 1px;
  width: 100%;
  // Tablet
  @media (max-width: $tablet-size) {
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0;
    width: auto;
  }
  .home-description-content {
    flex-direction: column;
    align-items: center;
    @media (max-width: $tablet-size) {
      flex-direction: column;
      align-items: center;
    }
  }
  .home-description-heading {
    margin-top: 5px;
    font-weight: $bold;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    @media (max-width: $tablet-size) {
      font-size: 2rem;
      text-align: center;
    }
  }
  .home-description-desktop-row-wraper {
    display: flex;
    justify-content: center;
  }
  .home-description-rows {
    flex: 11;
    margin-top: 50px;
    // Tablet
    @media (max-width: $tablet-size) {
      margin-top: 30px;
    }
    // Phone
    @media (max-width: $phone-size) {
      margin-top: 15px;
    }
    .home-description-row {
      display: flex;
      &:not(:first-child) {
        margin-top: 30px;
        // Desktop
        @media (min-width: $tablet-size+1) {
          margin-top: 30px;
        }
      }
      .home-description-row-icon {
        flex: 1 1 auto;
        font-size: 20px;
        //Desktop
        @media (min-width: $tablet-size+1) {
          font-size: 30px;
        }
      }
      .home-description-row-text {
        font-size: 1rem;
        text-align: justify;
        line-height: 25px;
        // Desktop
        @media (min-width: $tablet-size+1) {
          flex: 10;
          padding-left: 40px;
          padding-right: 40px;
        }
        .subinfo {
          margin-top: 10px;
          font-size: 12px;
          opacity: 0.6;
          // Tablet
          @media (max-width: $tablet-size) {
            font-size: 10px;
          }
          @media (max-width: $phone-size) {
            font-size: 8px;
          }
        }
      }
    }
  }
  .home-description-proximag-container {
    margin-top: 50px;
    flex: 4;
    .home-description-proximag-mag {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .home-description-proximag-button{
      margin-top: 20px;
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue;
        padding: 10px 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        outline: none;
        text-transform: inherit;
        font-size: 1rem;
        font-family: $font-family;
        width: 100%;
        color: $white;
        font-weight: $semi-bold;
        &:hover{
          opacity: 0.8;
          transition: opacity 0.1s ease-in-out;
        }
      }
    }
  }
}

.home-tutorial-container{
  height: auto;
  width: 100vw;
  background: $light-grey;
  padding: 50px;
  @media (max-width: $phone-size) {
    padding: 15px;
  }
  .home-tutorial-title{
    margin-bottom: 50px;
    font-weight: $bold;
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    @media (max-width: $phone-size) {
      font-size: 2rem;
      text-align: center;
    }
  }
  .home-tutorial-wrapper{
    max-width: 900px;
    margin: 0 auto;
    .home-tutorial-video{
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.home-middle {
  margin-top: 50px;
  position: relative;
  max-height: 400px;
  overflow: hidden;
  @media (max-width: $phone-size) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .home-middle-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .home-middle-heading {
      font-size: 2.5rem;
      font-weight: $bold;
      margin-top: 5px;
      margin-bottom: 5px;
      @media (max-width: $phone-size) {
        font-size: 2rem;
        text-align: center;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
  }
  .home-middle-button {
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 1.2rem;
    @media (max-width: $phone-size) {
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 1rem;
    }
  }
}

.home-client-counter {
  background-color: $light-grey;
  display: flex;
  justify-content: center;
  width: 100%;
  .home-client-counter-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $tablet-size+1) {
      height: 50px;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .home-client-counter-row {
      display: flex;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-left: 10px;
      margin-right: 10px;
      .icon {
        display: flex;
        align-items: center;
        font-size: 20px;
      }
      .text {
        display: flex;
        align-items: center;
        padding-left: 5px;
        transform: translateY(-1px);
        b {
          margin-right: 3px;
        }
        // Tablet
        @media (max-width: $tablet-size) {
          margin-top: 3px;
          margin-bottom: 3px;
        }
        // Phone
        @media (max-width: $phone-size) {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.aws-sld{
  height: 300px;
  display: flex;
  z-index: 0;
  .aws-sld__content{
    background: white;
    padding: 10px;
  }
  .aws-sld__next,
  .aws-sld__prev{
    @media (max-width: $tablet-size) {
      display: none;
    }
  }
  .aws-sld__bullets{
    display: none;
    @media (max-width: $tablet-size) {
      display: block;
      display: flex;
      margin-bottom: 50px;
      z-index: 100;
      button{
        width: 5px;
        height: 5px;
      }
    }
  }
}

img.middle-image-scale {
  max-width: 140%;
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0.25;
  // Tablet
  @media (min-width: $tablet-size+1) {
    width: 100%;
  }
  // Tablet
  @media (max-width: $tablet-size) {
    max-width: 160%;
  }
  // Tablet
  @media (max-width: $phone-size) {
    max-width: 140%;
  }
}

img.scale {
  width: 100%;
  // max-width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0.25;
}

.alice-carousel{
  margin-top: 20px;
  margin-bottom: 20px;
  .alice-carousel__dots-item{
    background: $gray-medium;
  }
  .alice-carousel__dots-item.__active{
    background: $blue;
  }
}
