@import "~styles/config.scss";

.container{
    background: $light-grey;
    &__item{
        margin-bottom: 10px;
        padding: 15px 10px;
        color: white;
        cursor: pointer;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $semi-bold;
        outline: none;
    }
}