@import "~styles/config.scss";

.order-recap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  // MODAL
  .warning-modal-content {
    // width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px 5px 0px;

    .title {
      padding: 5px;
      font-size: 18px;
      font-weight: 500;
    }
    .message {
      padding: 10px;
      text-align: center;
    }
    .button {
      padding: 10px;
    }
  }
  .loading-modal {
    // min-width: 50vw;
    justify-content: center;
    padding: 50px;
    text-align: center;
    .title {
      font-size: 1rem;
      margin-bottom: 2em;
    }
  }
}

// USER INFORMATION
.user-info {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 25px;
  // Phone
  @media (max-width: $phone-size) {
    font-size: 16px;
  }
  .user-name {
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
    // Tablet and Phone
    @media (max-width: $tablet-size) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .user-email {
    margin-left: 20px;
    margin-right: 20px;
    // Tablet and Phone
    @media (max-width: $tablet-size) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

// MAIN CONTENT CONTAINER
.order-recap-main {
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: $tablet-size) {
    flex-direction: column;
  }
  // ORDER ITEMS
  .order-items-container {
    flex: 1 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    // Tablet and Phone
    @media (max-width: $tablet-size) {
      margin-left: 10px;
      margin-right: 10px;
    }
    // ORDER-ITEM
    .order-item {
      margin-bottom: 5px;
      background-color: $light-grey;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-transform: capitalize;
      // The Item
      .order-item-content {
        flex: 1 1 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 20px;
        margin-right: 30px;
        @media (max-width: $phone-size) {
          margin-top: 10px;
          margin-bottom: 10px;
          margin-right: 20px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-wraper {
          flex: 1 1 auto;
          .item-date {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .icon {
          font-size: 20px;
          // Phone
          @media (max-width: $phone-size) {
            font-size: 11px;
          }
          transform: translateY(2px);
          &:hover {
            cursor: pointer;
          }
        }
        .text {
          color: $blue;
          font-size: 1.2rem;
          font-weight: $semi-bold;
          @media (max-width: $phone-size) {
            font-size: 12px;
          }
        }
      }
      // If the item is missing
      .order-item-missing {
        margin-left: 20px;
        height: 50px;
        display: flex;
        align-items: center;
      }
      .button {
        @media (max-width: $phone-size) {
          font-size: 10px;
        }
      }
    }
  }

  // ORDER RECAP BOX AND HELP
  .order-help-container {
    flex: 1 1;
    margin-bottom: 20px;
    max-width: 450px;
    // background-color: red;

    // TABLET AND PHONE
    @media (max-width: $tablet-size) {
      flex: 1 1 auto;
    }
    .order-help-order {
      margin-left: 20px;
      margin-right: 20px;
      // TABLET AND PHONE
      @media (max-width: $tablet-size) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
