@import "~styles/config.scss";

.navbar-container {
  display: flex;
  height: 40px;
  margin-left: auto;
}

.navbar-item {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: $semi-bold;
  font-size: 1rem;
  color: $blue;
  margin-right: 10px;
  outline: none;
  &:hover {
    cursor: pointer;
    color: $pink;
  }
}
