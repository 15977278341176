@import "~styles/config.scss";

.container{
    cursor: pointer;
    &__icon{
        color: $blue;
        margin-left: 20px;
        @media (max-width: $phone-size) {
            margin-left: 0px;
          }
    }
    span{
        color: $blue;  
    }
}