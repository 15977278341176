@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}
html,
body {
  min-height: 100vh;
}
html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  position: relative;
  margin: 0;
  counter-reset: heading-counter;
  text-size-adjust: 100%;
  font-family: $font-family;
  color: $black;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  color: $black;
}
button {
  background: transparent;
  outline: none;
  border: none;
  text-transform: uppercase;
}
