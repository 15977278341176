@import '~styles/config.scss';

.stripe-input-container {
    margin-bottom: 15px;
    margin-top: 15px;
}
.input-container {
  .StripeElement {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid $company-gray;
    font-size: 1rem;
    outline: none;
    border-radius: 0px;
    background: transparent;
  }
  .StripeElement-err {
    border: none;
    border-bottom: 1px solid $red-dark;
    color: $red-dark;
    // &::placeholder {
    //   color: $red-dark;
    // }
    // &::-ms-input-placeholder {
    //   color: $red-dark;
    // }
  }

  .text-input-error-placeholder {
    color: red;
    display: flex;
    align-items: flex-start;
    height: 15px;
    // Phone
    @media (max-width: $phone-size) {
      height: 4px;
      justify-content: flex-end;
    }
    .text-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px;
      // Phone
      @media (max-width: $phone-size) {
        font-size: 8px;
      }
    }
  }
}
