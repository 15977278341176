@import "~styles/config.scss";
.container{
    text-align: center;
    font-size: 0.9rem;
    font-weight: $semi-bold;
    margin-top: 20px;
    &__button{
        text-decoration: underline;
        cursor: pointer;
        outline: none;
        &:hover{
            opacity: 0.8;
        }
    }
}