@import "~styles/config.scss";

.editions-container {
  height: 100%;
  margin: 0 auto;

  // SEARCH
  .editions-search {
    display: flex;
    justify-content: center;
    margin-top: -25px;

    // Desktop
    @media (min-width: $tablet-size+1) {
      padding-top: 10px;
      padding-bottom: 50px;
      padding-left: 40px;
      padding-right: 20px;
    }
    // Tablet
    @media (max-width: $tablet-size) {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    // Phone
    @media (max-width: $phone-size) {
      padding: 10px 0;
    }
    .editions-search-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      // Desktop
      @media (min-width: $tablet-size+1) {
        // width: 90%;
      }
      // Tablet
      @media (max-width: $tablet-size) {
        align-items: flex-start;
      }
      // Phone
      @media (max-width: $phone-size) {
        display: flex;
        align-items: stretch;
      }
      .search-title {
        display: flex;
        justify-content: center;
        .text-button-container {
          color: white;
          max-width: 189px;
          margin: 0 auto;
          margin-bottom: 20px;

        }
        @media (min-width: $tablet-size+1) {
          font-size: 1.2rem;
          max-width: 450px;
          text-align: center;
          margin-bottom: 25px;
        }
        // Tablet and smaller
        @media (max-width: $tablet-size) {
          flex: 3;
          .search-title-content {
            width: 90%;
          }
        }
        @media (max-width: $phone-size) {
          font-size: 12px;
          margin-top: 20px;
          .search-title-content {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
      .search-input {
        display: flex;
        justify-content: center;
        @media (max-width: $tablet-size) {
          margin: 0 auto;
        }
        // Desktop
        @media (min-width: $tablet-size+1) {
          width: 300px;
          .search-input-content {
            width: 90%;
          }
        }
        .search-input-content {
          @media (max-width: $tablet-size) {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        .dropdown {
          position: relative;
          width: 100%;
        }
        .error {
          text-align: center;
          margin-top: 2px;
          height: 15px;
          font-size: 10px;
          color: $red-dark;
          // Desktop
          @media (min-width: $tablet-size+1) {
            display: none;
          }
        }
      }
    }
  }

  // ACCORDEON - DATES - PRINCING
  .editions-accordeon-dates-pricing {
    margin-bottom: 30px;
    @media (max-width: $phone-size) {
      padding: 0;
      margin-top: 20px;
    }
    .editions-accordeon-dates-pricing-content {
      display: flex;
      justify-content: center;
      @media (max-width: $tablet-size) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      // ACCORDEON - DATES
      .accordeon-dates {
        flex: 1 1;
        .accordeon-dates-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          // Tablet and Phone
          @media (max-width: $tablet-size) {
            flex-direction: column;
          }
          .accordeon {
            flex: 1 1 auto;
            // Tablet and Phone
            @media (max-width: $tablet-size) {
              flex: 1 1 auto;
              margin-bottom: 0px;
            }
            display: flex;
            justify-content: center;
            .accordeon-content {
              width: 90%;
              // Phone
              @media (max-width: $phone-size) {
                width: 100%;
              }
            }
            .dropdown-container {
              &:not(:first-child) {
                margin-top: 10px;
              }
            }
          }
          // DATES
          .dates {
            display: flex;
            justify-content: center;
            flex: 1 1 auto;
            margin-top: 30px;
            // Phone
            @media (max-width: $tablet-size) {
              margin-bottom: 40px;
            }
            .dates-content {
              p {
                padding: 1em;
                margin: 0;
              }
              width: 90%;
              height: fit-content;
              background: $light-grey;
              // Phone
              @media (max-width: $phone-size) {
                width: 100%;
              }
              .dates-header {
                background-color: $blue;
                color: white;
                padding: 15px 10px;
                font-size: 1rem;
                font-weight: $semi-bold;
                display: flex;
                align-items: center;
                // Phone
                @media (max-width: $phone-size) {
                  font-size: 16px;
                }
              }
              .dates-loading {
                padding-top: 40px;
                padding-bottom: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .dates-list {
                background-color: beige;
                .dates-error {
                  padding-top: 10px;
                  padding-bottom: 10px;
                  padding-left: 5px;
                  margin-top: 5px;
                  font-size: 12px;
                  color: $red-dark;
                }
                .dates-placeholder {
                  text-align: center;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  font-size: 11px;
                }
              }
            }
          }
        }
      }

      // PRICING
      .order-help {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1;
        max-width: 450px;
        // Tablet and Phone
        @media (max-width: $tablet-size) {
          flex: 2;
        }
        .order-help-content {
          width: 90%;
          @media (max-width: $phone-size) {
            width: 100%;
          }
        }
      }
    }
  }

  .editions-search-dropdown-option {
    padding: 5px;
    &:hover {
      cursor: pointer;
      background-color: $pink;
      color: white;
      font-weight: $semi-bold;
    }
    &:active {
      background-color: $pink;
    }
    &.selected {
      background-color: $pink;
    }
  }

  .editions-mention {
    text-align: center;
  }
}
