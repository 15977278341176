@import "~styles/config.scss";

.container{
    padding: 20px;
    &__wrapper{
        @media (min-width: $tablet-size+1) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
        h4{
            margin: 0;
        }
        &__items{
            padding: 0;
            list-style: none;
            font-size: 0.9rem;
            &__subItems{
                padding: 0;
                list-style: none;
                font-size: 0.9rem;
            }
        }
        ul{
            font-size: 0.9rem;
            h5,
            h6{
                font-size: 1rem;
                margin-bottom: -5px;
            }
            li{
                margin-bottom: 10px;
            }
        }
    }
    &__footnote{
        margin-top: 30px;
        font-size: 0.8rem;
        color: $gray-dark;
    }
}