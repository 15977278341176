@import "~styles/config.scss";

.personalInfo-container {
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  // Phone
  @media (max-width: $phone-size) {
    margin-left: 10px;
    margin-right: 10px;
  }

  // TITLE
  .personalInfo-title {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    background: linear-gradient($company-gray, $company-gray) bottom no-repeat;
    background-size: 20% 2px;
    // Phone
    @media (max-width: $phone-size) {
      margin-bottom: 5px;
    }
  }

  // Form
  .personalInfo-forms {
    margin-top: 10px;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
    width: 50%;
    // Phone
    @media (max-width: $tablet-size) {
      width: 80%;
    }
    // Phone
    @media (max-width: $phone-size) {
      width: 100%;
    }
    .personalInfo-form {
      margin-left: 20px;
      margin-right: 20px;
      // Phone
      @media (max-width: $phone-size) {
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
      }
      flex: 1 1 auto;
      .field {
        display: flex;
        align-items: baseline;
        // Phone
        @media (max-width: $phone-size) {
          flex-direction: column;
          align-items: stretch;
        }
        label {
          flex: 1 1 auto;
          // Phone
          @media (max-width: $phone-size) {
            font-size: 12px;
            margin-bottom: 2px;
          }
        }
        .input {
          flex: 2;
          .dropdowncontainer {
            // /!\parent MUST be relative for overlap !!
            position: relative;
            width: 100%;
            .social-reason-option {
              padding: 2px 5px 2px 5px;
              &:hover {
                cursor: pointer;
                color: white;
                background-color: $pink;
              }
            }
          }
        }
        .buttons {
          flex: 2;
          display: flex;
          justify-content: space-between;
          // Tablet & Phone
          @media (max-width: $tablet-size) {
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .placeHolder {
            width: 15px;
          }
          .button {
            flex: 1 1 auto;
          }
        }
      }
    }
    .loading-container {
      flex: 1 1 auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.form-hidden {
  display: none;
}