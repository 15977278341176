@import "~App.scss";

.signUp--input-container{
    @media (max-width: $tablet-size){
        padding-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: $phone-size){
        padding-left: 0px;
        margin-right: 0px;
    }
    label{
        font-size: 1rem;
        font-weight: $semi-bold;
        letter-spacing: 0.2px;
    }
    .signUp--input-wrapper{
        input,
        textarea{
            width: 100%;
            margin-top: 30px;
            outline: none;
            margin-bottom: 30px;
            border: none;
            border-bottom: 1px solid $company-gray;
            font-size: 1rem;
            outline: none;
            border-radius: 0px;
            background: transparent;
        }
        textarea{
            border: 1px solid $company-gray;
            font-family: $font-family;
            height: 200px;
        }
        select {
            width: 100%;
            margin-top: 20px;
            outline: none;
            margin-bottom: 30px;
            border: none;
            background: transparent;
          }
        span{
            color: $red-dark;
            border-top: 1px solid $red-dark;
            width: 100%;
            display: block;
            margin-top: -30px;
            margin-bottom: 20px;
            font-size: 0.8rem;
            padding-top: 10px;
            font-weight: 600;
        }
    }
}