@import "~styles/config.scss";
// Normal screen
.header-desktop-container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.header-desktop-logo-container {
  margin-right: 15px;
  height: 60%;
  cursor: pointer;
  outline: none;
  &:hover{
    opacity: 0.8;
    transition: opacity 0.1s ease-in-out;
  }
  img {
    height: 100%;
  }
}