@import "~styles/config.scss";

.container {
  color: white;
  width: 35px;
  height: 31px;
  background-color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $pink;
    cursor: pointer;
  }
}
