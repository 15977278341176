@import "~styles/config.scss";

#root {
  min-height: 100vh;
  .page-loading{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .App {
    height: 100%;
    display: flex;
    flex-direction: column;

    #header {
      @media (max-width: $tablet-size) {
        position: fixed;
        top: 0px;
        z-index: 1;
        height: $mobile-header-height;
        width: 100%;
      }
    }
    #content {
      flex: 1 1 auto;
      min-height: 1px;
      @media (max-width: $phone-size) {
        margin-top: $mobile-header-height;
      }
    }
    #footer {
      flex-shrink: 0;
    }
  }
}
