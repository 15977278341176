@import "~styles/config.scss";

.container{
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    cursor: pointer;
    outline: none;
    &__check{
        margin-right: 5px;
    }
    &__title{
        @media (max-width: $phone-size) {
            font-size: 0.8rem;
        }
        span{
            text-decoration: underline;
            &:hover{
                opacity: 0.7;
            }
        }
    }
}