@import "~styles/config.scss";

.signUp-container {
  min-height: 50vh;
  .signUp-container--message {
    display: block;
    padding: 20px;
    margin-bottom: 20px;
    background-color: $blue;
    color: white;
  }
  .signUp-container--header {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 50px;
    @media (max-width: $phone-size) {
      margin-bottom: 20px;
    }
  }

  .form-hidden {
    display: none;
  }

  form {
    width: 100%;
    @media (max-width: $phone-size) {
      margin-top: 30px;
    }
    .signUp_reset{
      color: $blue;
      text-align: center;
      font-size: 0.8rem;
      margin-bottom: 20px;
      font-weight: $semi-bold;
      cursor: pointer;
      &:hover{
        opacity: 0.7;
      }
    }
    .signUp-container--row {
      display: flex;
      justify-content: space-between;
      @media (max-width: $tablet-size) {
        flex-direction: column;
      }
      .signUp--input-container {
        width: 48%;
        @media (max-width: $tablet-size) {
          width: unset;
        }
      }
    }
    .signUp-container--row_3-4 {
      display: flex;
      justify-content: space-between;
      @media (max-width: $tablet-size) {
        flex-direction: column;
      }
      .signUp--input-container {
        &:first-child {
          flex: 4;
          margin-right: 10px;
        }
        &:last-child {
          flex: 1 1 auto;
        }
      }
    }
  }

  .signUp-buttons--row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: $phone-size) {
      flex-direction: column-reverse;
    }
    .text-button-container{
      margin: 10px;
    }
  }
}
