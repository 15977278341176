@import "~styles/config.scss";

.faq-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px;
  @media (max-width: $phone-size) {
    margin: 10px;
  }
  .faq-wrapper {
    margin-bottom: 40px;
    @media (max-width: $phone-size) {
      margin-bottom: 20px;
    }
    .faq-question-wrapper {
      color: $black;
      font-weight: bold;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover{
        color: $blue;
      }
      @media (max-width: $phone-size) {
        font-size: 1rem;
      }
    }
    .faq-answer-wrapper {
      display: none;
      font-size: 1.1rem;
      margin-top: 10px;
      @media (max-width: $phone-size) {
        margin: 0;
        margin-top: 10px;
        font-size: 0.9rem;
      }
    }
    .faq-answer-wrapper--open {
      display: block;
    }
  }
  .faq-tooltip{
    color: $blue;
    font-weight: $semi-bold;
    margin-top: 30px;
    @media (max-width: $phone-size) {
      font-size: 0.9rem;
    }
    p{
      margin: 2px;
      a{
        color: $blue;
        text-decoration: underline;
        &:hover{
          opacity: 0.8;
        }
      }
    }
  }
}
