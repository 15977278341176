@import "~styles/config.scss";

.format-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // Phone
  @media (max-width: $phone-size) {
    padding: 0;
  }

  // SUBTITLE
  .format-subtitle {
    display: flex;
    justify-content: center;
    width: 100%;
    .format-subtitle-content {
      font-size: 1.2rem;
      max-width: 600px;
      text-align: center;
      margin-bottom: 50px;
      // Phone
      @media (max-width: $phone-size) {
        flex-direction: column;
        text-align: justify;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

  // MAIN-CONTENT
  .format-main {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .format-main-content {
      width: 100%;
      display: flex;
      justify-content: center;
      // Phone
      @media (max-width: $tablet-size) {
        flex-direction: column;
      }
      .formats {
        flex: 1 1;
        //Tablet
        @media (max-width: $tablet-size) {
          flex: 1 1;
        }
        .formats-content {
          display: flex;
          width: 100%;
          // Tablet
          @media (min-width: $tablet-size+1) {
            justify-content: center;
          }

          // Tablet
          @media (max-width: $tablet-size) {
            flex-direction: column;
            align-items: center;
          }
          .format-category {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;
            .format-category-content {
              margin-right: 20px;
              width: 100%;
              // Phone
              @media (max-width: $tablet-size) {
                margin: 0;
              }
              background-color: $light-grey;
              height: fit-content;
              .header {
                text-transform: capitalize;
                background-color: $blue;
                color: white;
                padding: 15px 10px;
                font-size: 18px;
                display: flex;
                align-items: center;
                font-weight: $semi-bold;
                // Phone
                @media (max-width: $phone-size) {
                  font-size: 16px;
                }
              }
              .formats-list {
                padding: 10px 0px 10px 0px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                max-width: 460px;
                margin: 0 auto;
                // Adjust min and max size to better suite
                // flex layout.
                .format-item {
                  flex: 1 1 auto;
                  min-width: 165px;
                  max-width: 215px;
                  padding: 10px 4px 10px 4px;
                  @media (max-width: $phone-size) {
                    min-width: 150px;
                    max-width: 120px;
                  }
                }
              }
            }
          }
        }
      }
      .order-help {
        flex: 1 1;
        margin-bottom: 20px;
        max-width: 450px;
        // TABLET
        @media (max-width: $tablet-size) {
          flex: 2;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        .order-help-content {
          width: 100%;
          // Phone
          @media (max-width: $phone-size) {
            width: 100%;
          }
        }
      }
    }
  }

  .format-mention {
    text-align: center;
  }
}
