@import "~styles/config.scss";

.container{
    min-height: 1px;
    &__cover{
        background-position: center;
        background-size: cover;
        height: 70vh;
        width: 100vw;
    }
    &__larger,
    &__smaller{
        margin: 0 auto;
        background: $white;
        box-shadow: 2px 10px 13px 2px rgba(0, 0, 0, 0.05);
        margin-bottom: 50px;
        margin-top: -250px;
        &__title{
            text-align: center;
            font-size: 2.5rem;
            font-weight: $bold;
            max-width: 550px;
            margin: 0 auto;
            margin-bottom: 50px;
            @media (max-width: $phone-size) {
                margin-bottom: 20px;
                font-size: 2rem;
            }
        }
        @media (max-width: $phone-size) {
            margin-top: -350px; 
        }
        @media (max-height: 750px) and (min-width: $tablet-size+1) {
            margin-top: -300px; 
        }
    }
    &__larger{
        max-width: 1000px;
        padding: 70px 50px;
        @media (max-width: 1040px) {
            margin-left: 20px;
            margin-right: 20px;    
        }
        @media (max-width: $tablet-size) {
            padding: 30px 10px;
        }
    }
    &__smaller{
        max-width: 500px;
        padding: 30px 30px;
        @media (max-width: 540px) {
            margin-left: 20px;
            margin-right: 20px;    
        }
        @media (max-width: $phone-size) {
            padding: 30px 10px;
        }
    }
}