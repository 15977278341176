@import "~styles/config.scss";

.text-input-container {
  .input-container {
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      outline: none;
      border: none;
      border-bottom: 1px solid $company-gray;
      font-size: 1rem;
      outline: none;
      border-radius: 0px;
      background: transparent;
      // Phone

      &.text-input-err {
        border: none;
        border-bottom: 1px solid $red-dark;
        color: $red-dark;
        &::placeholder {
          color: $red-dark;
        }
        &::-ms-input-placeholder {
          color: $red-dark;
        }
      }
      &:focus {
        outline: none;
      }
    }

    .text-input-error-placeholder {
      color: red;
      display: flex;
      align-items: flex-start;
      height: 15px;
      // Phone
      @media (max-width: $phone-size) {
        height: 4px;
        justify-content: flex-end;
      }
      .text-input-error {
        display: flex;
        align-items: flex-start;
        height: 100%;
        font-size: 10px;
        // Phone
        @media (max-width: $phone-size) {
          font-size: 8px;
        }
      }
    }
  }
}

// Removes the ugly blue outline and autocomplete background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
