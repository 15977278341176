@import '~styles/config.scss';
.container{
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    &__tooltip{
        font-size: 0.9rem;
        font-weight: $semi-bold;
        margin-bottom: 10px;
        width: 100%;
    }
    input{
        &:not(:first-of-type){
            margin-left: 15px;
        }
    }
    label{
        img{
            height: 30px;
        }
    }
}