@import "~styles/config.scss";
.error-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    color: $red-dark;
  }
  .error {
    padding: 10px;
    margin-bottom: 40px;
  }
  .button {
    text-transform: capitalize;
  }
}
