@import "~styles/config.scss";

.container{
    padding: 20px;
    position: relative;
        @media (min-width: $tablet-size+1) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        &__list{
            padding: 0;
            list-style: none;
            font-size: 0.9rem;
            ul{
                font-size: 0.9rem;
                list-style: disc;
                padding-left: 35px;
                li{
                    margin-bottom: 10px;
                }
            }
        }
        h5,
        h6{
            font-size: 1rem;
            margin: 0;
            margin-bottom: -5px;
        }
    }
}