@import "~styles/config.scss";

.file-input-container {
  width: 100%;
  .file-input-label {
    width: 100%;

    .file-input-label-content {
      display: flex;
      align-items: center;
      border: 1.5px solid $black;
      background-color: white;
      padding: 3px;

      .file-input-text {
        margin: 5px 2px 5px 2px;
        height: 21px;
        flex: 1 1 auto;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        // white-space: nowrap; // this really screws things up...
        padding: 0px 5px 0px 5px;
        // Phone
        @media (max-width: $phone-size) {
          height: 16px;
          font-size: 11px;
        }
      }

      &.err {
        color: $red-dark;
        border: 2px solid $red-dark;
      }
    }

    .file-input-button {
      flex-shrink: 1;
      // Phone
      @media (max-width: $phone-size) {
        font-size: 10px;
      }
    }
  }

  input {
    display: none;
    &:focus {
      outline: none;
    }
  }

  .file-input-error-placeholder {
    // background-color: azure;
    color: red;
    display: flex;
    align-items: flex-start;
    height: 16px;
    padding-left: 12px;
    .file-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px;
    }
  }
}

// Removes the ugly blue outline and autocomplete background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
