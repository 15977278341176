@import "~styles/config.scss";

.upload-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  padding: 5px 10px;
  outline: none;
  &.rounded {
    border-radius: 10px;
  }
  &.fit {
    flex: 1 1 auto;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:active {
    background-color: $pink;
  }
  .text-button-text {
    color: white;
    font-weight: $semi-bold;
  }
}
