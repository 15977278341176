@import "~styles/config.scss";

.dropdown-container {
  width: 100%;
  .dropdown-content {
    width: 100%;
    .dropdown-header {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: $semi-bold;
      &.standalone {
        color: white;
        background-color: $blue;
        font-size: 20px;
        height: 55px;
        padding: 20px 10px;
      }
      &.open {
        background-color: $blue;
      }
      &.closed {
        background-color: $blue;
        opacity: 0.5;
      }
      &.part-of-form {
        background-color: white;
        border: 2px solid black;
        font-size: 14px;
        line-height: 37px;
        @media (max-width: $phone-size) {
          line-height: 26px;
        }
      }
      &.red {
        color: red;
        border: 2px solid red;
      }
      .header-title {
        @media (max-width: $phone-size) {
          font-size: 14px;
        }
        &.part-of-form {
          color: rgb(114, 114, 114);
          transform: translateY(-1px);
          @media (max-width: $phone-size) {
            line-height: 18px;
            font-size: 12px;
          }
          &.value-selected {
            color: black;
          }
          &.red {
            color: red;
          }
        }
      }
      .header-icon {
        padding-right: 8px;
        &.open {
          transition: 0.9s;
          transform: rotate(90deg);
        }
      }
      &:hover {
        cursor: pointer;
      }
      @media (max-width: $tablet-size) {
        font-size: 16px;
      }
    }
    .dropdown-children {
      width: 100%;
      background-color: $light-grey;
      &.overlay {
        position: absolute;
        width: 100%;
        z-index: 1;
      }
    }
  }
  .dropdown-error-placeholder {
    color: red;
    display: flex;
    align-items: flex-start;
    height: 15px;
    padding-left: 5px;
    .text-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px;
    }
  }
}
