@import "../../App.scss";


.leaflet-container {
    height: 400px;
    width: 600px;
    z-index: 0;
    @media (max-width: $tablet-size) {
        width: 80vw;
        height: 200px;
    }
}