@import "~styles/config.scss";

.modifyPassword-container--message {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
  background-color: $blue;
  color: white;
}

.modifyPassword-container--error {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
  background-color: red;
  color: white;
}


.modifyPassword-buttons--row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: $phone-size) {
      flex-direction: column-reverse;
    }
    .text-button-container{
      margin: 10px;
    }
}

.loading-container {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editpwd-container--message {
  display: block;
  padding: 20px;
  margin: 10px 0;
  background-color: $blue;
  color: white;
}
span.editpwd-container--message--error {
  background-color: $red-dark;
}