@import "~styles/config.scss";
.loading-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-success {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
  }
}
