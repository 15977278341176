@import "~styles/config.scss";
.checkbox-container {
  .checkbox-content {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.centerText {
      justify-content: space-between;
    }
    @media (max-width: $phone-size) {
      height: 24px;
      font-size: 12px;
    }
    &:hover {
      cursor: pointer;
    }
    .checkContainer {
      &.centerText {
        width: 15%;
      }
      display: flex;
      align-items: center;
      color: white;
      .checkbox {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .textContainer {
      transform: translateY(-2px);
      user-select: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      &.textLink {
        text-decoration: underline;
        color: blue;
      }
      &.centerText {
        padding-right: 6px;
        padding-left: 3px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.8rem;
        width: 100%;
      }
    }
    .placeHolder {
      height: 10px;
      width: 15%;
    }
  }
}
