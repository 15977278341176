@import "~styles/config.scss";

.container{
    display: flex;
    border: 3px solid $light-grey;
    @media (max-width: $tablet-size) {
        flex-direction: column;
        border: none;
    }
    &__menu{
        flex: 1 1 auto;
        padding: 20px;
        list-style: none;
        border-right: 3px solid $light-grey;
        background: $light-grey;
        @media (max-width: $tablet-size) {
            display: flex;
            background: transparent;
            border: none;
            flex-wrap: wrap;
            padding: 10px;
        }
        @media (max-width: $phone-size){
            padding: 0;
        }
        li{
            font-weight: $bold;
            color: $blue;
            margin-bottom: 10px;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
            @media (max-width: $tablet-size) {
                margin-right: 10px;
            }
        }
    }
    &__content{
        flex: 3 3 auto;
        &__title{
            font-size: 1.2rem;
            font-weight: $bold;
            margin-bottom: 30px;
            border-bottom: 3px solid $light-grey;
            padding: 20px;
            @media (max-width: $tablet-size){
                padding: 10px;
            }
            @media (max-width: $phone-size) {
                padding: 0px;
                padding-top: 10px;
                margin-bottom: 10px;
                border: none;
            }
        }
        &__info{
            padding: 20px;
            padding-top: 0px;
            @media (max-width: $tablet-size){
                padding: 0px;
            }
        }
        &__flash{
            padding: 20px;
            margin: 10px 0;
            background-color: $blue;
            color: white;
        }
    }
}

.modal {
    &__content{
        margin: 0 auto;
        width: 80%
    }
    &__content__title {
        margin: 40px 0;
    }
}