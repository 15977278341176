@import "~styles/config.scss";

.container{
    &__isTop{
        background-color: $dark-blue;
        display: flex;
        padding: 50px 10%;
        color: $white;
        justify-content: space-between;
        @media (max-width: $tablet-size) {
            padding: 50px 15px;
        }
        @media (max-width: $phone-size) {
            flex-direction: column;
        }
        &__item{
            flex: 1 1 auto;
            &:not(last-of-type){
                margin-right: 20px;
            }
            @media (max-width: $phone-size) {
                margin-bottom: 30px;
            }
            &__img{
                margin-top: -20px;
                max-width: 200px;
                width: 100%;
                box-sizing:border-box;
                @media (max-width: $phone-size) {
                    width: 150px;
                    margin-left: -20px;
                    margin-top: -45px;
                }
            }
            &:first-child,
            &:nth-child(2){
                flex: 2 2 auto;
                    h6{
                        &:last-of-type{
                            margin-top: 20px;
                            margin-bottom: 20px;
                            @media (max-width: $phone-size) {
                                margin-top: 30px;
                            }
                        }
                    }
                    ul{
                        display: flex;
                        li{
                            margin-right: 20px;
                            opacity: 0.8;
                            a{
                                font-size: 1.3rem;
                            }
                        }
                    }
            }
            h6{
                margin: 0;
                font-size: 0.9rem;
                margin-bottom: 10px;
                opacity: 0.8;
                a{
                    color: $white;
                }
            }
            ul{
                list-style: none;
                padding: 0;
                li{
                    opacity: 0.5;
                    margin-bottom: 10px;
                    &:hover{
                        opacity: 0.8;
                    }
                    a{
                        color: $white;
                        font-size: 0.7rem;
                        font-weight: $semi-bold;
                    }
                }
            }
            p{
                font-size: 0.8rem;
                opacity: 0.8;
                line-height: 20px;
            }
        }
    }
    &__isBottom{
        background: $darker-blue;
        display: flex;
        justify-content: space-between;
        color: $white;
        padding: 10px 10%;
        align-items: baseline;
        @media (max-width: $phone-size) {
           flex-direction: column-reverse;
        }
        p{
            margin: 0;
            font-size: 0.6rem;
            opacity: 0.5;
        }
        ul{
            list-style: none;
            padding: 0;
            display: flex;
            @media (max-width: $phone-size) {
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;
            }
            li{
                opacity: 0.5;
                margin-left: 10px;
                @media (max-width: $phone-size) {
                    margin-left: 0px;
                }

                a,
                span{
                    text-decoration: underline;
                    color: $white;
                    font-size: 0.6rem;
                    cursor: pointer;
                }
            }
        }
    }
}