@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  margin: 0; }

.text-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2095a9;
  padding: 10px 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  outline: none;
  text-transform: inherit;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  width: auto; }
  .text-button-container.rounded {
    border-radius: 10px; }
  .text-button-container.fit {
    flex: 1 1 auto;
    width: 100%; }
  .text-button-container:hover {
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out; }
  .text-button-container:active {
    background-color: #ffcccb; }
  .text-button-container.pink {
    background-color: #ffcccb; }
    .text-button-container.pink:hover {
      cursor: pointer;
      opacity: 0.8; }
    .text-button-container.pink:active {
      opacity: 0.8;
      background-color: #2095a9; }
  .text-button-container .text-button-text {
    color: white;
    font-weight: 600; }

ul {
  margin: 0; }

.error-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .error-box .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    color: #e93838; }
  .error-box .error {
    padding: 10px;
    margin-bottom: 40px; }
  .error-box .button {
    text-transform: capitalize; }

ul {
  margin: 0; }

.loading-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading-box .loading-success {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px; }

ul {
  margin: 0; }

.currentOrder {
  color: #0b0b0b; }
  .currentOrder .currentOrder-content {
    background-color: #f9f9f9;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 425px) {
      .currentOrder .currentOrder-content {
        padding: 20px 10px; } }
    .currentOrder .currentOrder-content .currentOrder-title {
      display: flex;
      justify-content: center;
      padding: 5px 0 5px 0; }
      .currentOrder .currentOrder-content .currentOrder-title .currentOrder-title-content {
        font-size: 20px;
        font-weight: 600; }
    .currentOrder .currentOrder-content .currentOrder-edition {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 30px; }
      .currentOrder .currentOrder-content .currentOrder-edition .currentOrder-edition-content {
        width: 90%;
        display: flex;
        justify-content: space-between; }
        .currentOrder .currentOrder-content .currentOrder-edition .currentOrder-edition-content .currentOrder-edition-content-label {
          font-weight: 600;
          text-align: left; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-edition .currentOrder-edition-content .currentOrder-edition-content-label {
              font-size: 0.8rem; } }
        .currentOrder .currentOrder-content .currentOrder-edition .currentOrder-edition-content .currentOrder-edition-content-edition {
          text-align: right;
          -webkit-transform: translateY(3px);
                  transform: translateY(3px);
          font-size: 14px; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-edition .currentOrder-edition-content .currentOrder-edition-content-edition {
              font-size: 0.8rem; } }
    .currentOrder .currentOrder-content .currentOrder-dates {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 20px; }
      .currentOrder .currentOrder-content .currentOrder-dates .currentOrder-dates-content {
        width: 90%;
        display: flex;
        justify-content: space-between; }
        .currentOrder .currentOrder-content .currentOrder-dates .currentOrder-dates-content .currentOrder-dates-content-label {
          font-weight: 600;
          text-align: left; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-dates .currentOrder-dates-content .currentOrder-dates-content-label {
              font-size: 0.8rem; } }
        .currentOrder .currentOrder-content .currentOrder-dates .currentOrder-dates-content .currentOrder-dates-content-dates {
          text-align: right;
          text-transform: uppercase;
          -webkit-transform: translateY(3px);
                  transform: translateY(3px);
          font-size: 14px;
          line-height: 20px; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-dates .currentOrder-dates-content .currentOrder-dates-content-dates {
              font-size: 0.8rem; } }
    .currentOrder .currentOrder-content .currentOrder-format {
      display: flex;
      justify-content: center;
      flex: 1 1 auto;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 20px; }
      .currentOrder .currentOrder-content .currentOrder-format .currentOrder-format-content {
        width: 90%;
        display: flex;
        justify-content: space-between; }
        .currentOrder .currentOrder-content .currentOrder-format .currentOrder-format-content .currentOrder-format-content-label {
          font-weight: 600;
          text-align: left; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-format .currentOrder-format-content .currentOrder-format-content-label {
              font-size: 0.8rem; } }
        .currentOrder .currentOrder-content .currentOrder-format .currentOrder-format-content .currentOrder-format-content-format {
          text-align: right;
          -webkit-transform: translateY(3px);
                  transform: translateY(3px);
          font-size: 14px;
          text-transform: capitalize; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-format .currentOrder-format-content .currentOrder-format-content-format {
              font-size: 0.8rem; } }
    .currentOrder .currentOrder-content .currentOrder-price {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 2px 0 2px 0;
      margin-top: 40px;
      margin-bottom: 20px; }
      .currentOrder .currentOrder-content .currentOrder-price .currentOrder-price-content {
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        border-top: 1px solid #0b0b0b;
        padding-top: 20px; }
        .currentOrder .currentOrder-content .currentOrder-price .currentOrder-price-content .currentOrder-format-price-label {
          text-align: left; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-price .currentOrder-price-content .currentOrder-format-price-label {
              font-size: 0.8rem; } }
        .currentOrder .currentOrder-content .currentOrder-price .currentOrder-price-content .currentOrder-format-price-format {
          text-align: right;
          -webkit-transform: translateY(3px);
                  transform: translateY(3px);
          font-size: 14px; }
          @media (max-width: 425px) {
            .currentOrder .currentOrder-content .currentOrder-price .currentOrder-price-content .currentOrder-format-price-format {
              font-size: 0.8rem; } }
    .currentOrder .currentOrder-content div.currentOrder-price--ttc {
      margin-top: 0;
      flex-direction: column; }
      .currentOrder .currentOrder-content div.currentOrder-price--ttc .currentOrder-price-content {
        margin: 0 auto; }
    .currentOrder .currentOrder-content .currentOrder-button {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 5px 0 5px 0;
      margin-top: 20px; }
      .currentOrder .currentOrder-content .currentOrder-button .currentOrder-button-content {
        width: 90%;
        display: flex;
        justify-content: space-between; }
        .currentOrder .currentOrder-content .currentOrder-button .currentOrder-button-content .button-container {
          width: 100%;
          display: flex; }
        .currentOrder .currentOrder-content .currentOrder-button .currentOrder-button-content.multi {
          justify-content: space-around; }
        .currentOrder .currentOrder-content .currentOrder-button .currentOrder-button-content .button {
          flex: 1 1 auto; }
        .currentOrder .currentOrder-content .currentOrder-button .currentOrder-button-content .spacer {
          width: 5px; }
  .currentOrder .currentOrder-error {
    margin-top: 5px;
    background-color: #ffcccb; }
    .currentOrder .currentOrder-error .currentOrder-error-content .currentOrder-error-content-message {
      padding: 10px;
      color: #0b0b0b;
      text-align: left;
      font-size: 12px; }

ul {
  margin: 0; }

.style_container__3NuWR {
  min-height: 1px; }
  .style_container__cover__2MwJ3 {
    background-position: center;
    background-size: cover;
    height: 70vh;
    width: 100vw; }
  .style_container__larger__3P-IS, .style_container__smaller__Q94e0 {
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 2px 10px 13px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;
    margin-top: -250px; }
    .style_container__larger__title__3Vmpn, .style_container__smaller__title__2gfbG {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 700;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 50px; }
      @media (max-width: 425px) {
        .style_container__larger__title__3Vmpn, .style_container__smaller__title__2gfbG {
          margin-bottom: 20px;
          font-size: 2rem; } }
    @media (max-width: 425px) {
      .style_container__larger__3P-IS, .style_container__smaller__Q94e0 {
        margin-top: -350px; } }
    @media (max-height: 750px) and (min-width: 769px) {
      .style_container__larger__3P-IS, .style_container__smaller__Q94e0 {
        margin-top: -300px; } }
  .style_container__larger__3P-IS {
    max-width: 1000px;
    padding: 70px 50px; }
    @media (max-width: 1040px) {
      .style_container__larger__3P-IS {
        margin-left: 20px;
        margin-right: 20px; } }
    @media (max-width: 768px) {
      .style_container__larger__3P-IS {
        padding: 30px 10px; } }
  .style_container__smaller__Q94e0 {
    max-width: 500px;
    padding: 30px 30px; }
    @media (max-width: 540px) {
      .style_container__smaller__Q94e0 {
        margin-left: 20px;
        margin-right: 20px; } }
    @media (max-width: 425px) {
      .style_container__smaller__Q94e0 {
        padding: 30px 10px; } }

ul {
  margin: 0; }

.style_container__16gq_ {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap; }
  .style_container__tooltip__1cg89 {
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 10px;
    width: 100%; }
  .style_container__16gq_ input:not(:first-of-type) {
    margin-left: 15px; }
  .style_container__16gq_ label img {
    height: 30px; }

ul {
  margin: 0; }

@media (max-width: 425px) {
  .payment-container {
    margin-left: 10px;
    margin-right: 10px; } }

.payment-container .payment-main {
  display: flex; }
  @media (max-width: 425px) {
    .payment-container .payment-main {
      flex-direction: column; } }

.payment-container .payment-select {
  display: flex;
  padding: 0;
  margin: 0 20px 20px; }
  .payment-container .payment-select .payment-select-active a {
    background-color: transparent;
    color: black; }
  .payment-container .payment-select li {
    flex: 1 1 auto;
    text-align: center;
    list-style-type: none; }
    .payment-container .payment-select li:first-child a {
      margin-left: 0; }
    .payment-container .payment-select li:last-child a {
      margin-right: 0; }
    .payment-container .payment-select li a {
      background-color: #2095a9;
      color: white;
      display: block;
      margin: 0 10px;
      padding: 5px 0; }

.payment-container .payment-forms {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px; }
  .payment-container .payment-forms label {
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-bottom: 15px; }

.payment-container .order-help-container {
  flex: 1 1;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 450px; }

.payment-container .payment-forms--wrapper {
  position: relative;
  min-height: 300px; }

.payment-container .loading-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: white; }

ul {
  margin: 0; }

.style_container__2kbuK {
  cursor: pointer; }
  .style_container__icon__3L9Vk {
    color: #2095a9;
    margin-left: 20px; }
    @media (max-width: 425px) {
      .style_container__icon__3L9Vk {
        margin-left: 0px; } }
  .style_container__2kbuK span {
    color: #2095a9; }

ul {
  margin: 0; }

.navbar-container {
  display: flex;
  height: 40px;
  margin-left: auto; }

.navbar-item {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #2095a9;
  margin-right: 10px;
  outline: none; }
  .navbar-item:hover {
    cursor: pointer;
    color: #ffcccb; }

ul {
  margin: 0; }

.header-desktop-container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%; }

.header-desktop-logo-container {
  margin-right: 15px;
  height: 60%;
  cursor: pointer;
  outline: none; }
  .header-desktop-logo-container:hover {
    opacity: 0.8;
    -webkit-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out; }
  .header-desktop-logo-container img {
    height: 100%; }

ul {
  margin: 0; }

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 15px;
  font-weight: 600;
  color: #d1d1d1;
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }

.bm-item:hover {
  color: white; }

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 25px; }
  @media (max-width: 425px) {
    .bm-burger-button {
      width: 20px;
      height: 15px;
      left: 16px;
      top: 16px; } }

.bm-burger-bars {
  background: #2095a9; }

.bm-cross-button {
  height: 24px;
  width: 24px; }

.bm-cross {
  background: #bdc3c7; }

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em; }

.bm-morph-shape {
  fill: #373a47; }

.bm-item-list {
  color: #b8b7ad; }

.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }

ul {
  margin: 0; }

.style_connected__1uKug {
  display: flex;
  align-items: center; }

ul {
  margin: 0; }

.header-container {
  height: 80px;
  flex: 1 1 auto;
  background-color: #ebebeb;
  color: #2095a9; }
  @media (max-width: 425px) {
    .header-container {
      height: 50px; } }
  .header-container .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%; }

.header-empty-container {
  flex: 1 1 auto; }

.header-logo-container {
  flex: 1 1 auto;
  height: 70%;
  outline: none;
  position: absolute;
  left: 0;
  right: 0; }
  .header-logo-container img {
    height: 100%;
    width: 100%; }

.header-basket-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end; }
  @media (max-width: 768px) {
    .header-basket-container {
      margin-right: 15px; } }
  @media (max-width: 425px) {
    .header-basket-container {
      margin-right: 0; } }
  .header-basket-container .connection {
    margin-right: 5px; }

ul {
  margin: 0; }

.style_container__31eA_ {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.431); }
  .style_container__content__35ktY {
    margin: 10px;
    background-color: white;
    opacity: 1;
    position: fixed;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    max-height: 200px;
    left: 0;
    right: 0; }
    .style_container__content__close__2ki-5 {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      cursor: pointer;
      outline: none;
      z-index: 100; }

ul {
  margin: 0; }

.style_container__2BDft {
  padding: 30px; }
  @media (min-width: 769px) {
    .style_container__wrapper__3_c0- {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2; } }
  .style_container__wrapper__3_c0- h4 {
    margin: 0; }
  .style_container__wrapper__3_c0- p {
    font-size: 0.9rem; }
  .style_container__wrapper__3_c0- ul {
    list-style: none;
    padding: 0;
    font-size: 0.9rem; }
    .style_container__wrapper__3_c0- ul li {
      margin-top: 10px; }
  .style_container__2BDft p {
    font-size: 0.9rem;
    margin: 0;
    margin-top: 20px; }
  .style_container__2BDft span {
    font-size: 0.8rem;
    color: #606060; }

ul {
  margin: 0; }

.style_container__oZmPB {
  padding: 20px; }
  @media (min-width: 769px) {
    .style_container__wrapper__3cy0M {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2; } }
  .style_container__wrapper__3cy0M h4 {
    margin: 0; }
  .style_container__wrapper__items__3fiAK {
    padding: 0;
    list-style: none;
    font-size: 0.9rem; }
    .style_container__wrapper__items__subItems__1-tP3 {
      padding: 0;
      list-style: none;
      font-size: 0.9rem; }
  .style_container__wrapper__3cy0M ul {
    font-size: 0.9rem; }
    .style_container__wrapper__3cy0M ul h5,
    .style_container__wrapper__3cy0M ul h6 {
      font-size: 1rem;
      margin-bottom: -5px; }
    .style_container__wrapper__3cy0M ul li {
      margin-bottom: 10px; }
  .style_container__footnote__3rCHK {
    margin-top: 30px;
    font-size: 0.8rem;
    color: #606060; }

ul {
  margin: 0; }

.style_container__36L91 {
  padding: 20px;
  position: relative; }
  @media (min-width: 769px) {
    .style_container__36L91 {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2; }
      .style_container__list__1TjGm {
        padding: 0;
        list-style: none;
        font-size: 0.9rem; }
        .style_container__list__1TjGm ul {
          font-size: 0.9rem;
          list-style: disc;
          padding-left: 35px; }
          .style_container__list__1TjGm ul li {
            margin-bottom: 10px; }
      .style_container__36L91 h5,
      .style_container__36L91 h6 {
        font-size: 1rem;
        margin: 0;
        margin-bottom: -5px; } }

ul {
  margin: 0; }

.style_container__isTop__1ciYZ {
  background-color: #0b353d;
  display: flex;
  padding: 50px 10%;
  color: #ffffff;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .style_container__isTop__1ciYZ {
      padding: 50px 15px; } }
  @media (max-width: 425px) {
    .style_container__isTop__1ciYZ {
      flex-direction: column; } }
  .style_container__isTop__item__2koAI {
    flex: 1 1 auto; }
    .style_container__isTop__item__2koAI:not(last-of-type) {
      margin-right: 20px; }
    @media (max-width: 425px) {
      .style_container__isTop__item__2koAI {
        margin-bottom: 30px; } }
    .style_container__isTop__item__img__FXq3b {
      margin-top: -20px;
      max-width: 200px;
      width: 100%;
      box-sizing: border-box; }
      @media (max-width: 425px) {
        .style_container__isTop__item__img__FXq3b {
          width: 150px;
          margin-left: -20px;
          margin-top: -45px; } }
    .style_container__isTop__item__2koAI:first-child, .style_container__isTop__item__2koAI:nth-child(2) {
      flex: 2 2 auto; }
      .style_container__isTop__item__2koAI:first-child h6:last-of-type, .style_container__isTop__item__2koAI:nth-child(2) h6:last-of-type {
        margin-top: 20px;
        margin-bottom: 20px; }
        @media (max-width: 425px) {
          .style_container__isTop__item__2koAI:first-child h6:last-of-type, .style_container__isTop__item__2koAI:nth-child(2) h6:last-of-type {
            margin-top: 30px; } }
      .style_container__isTop__item__2koAI:first-child ul, .style_container__isTop__item__2koAI:nth-child(2) ul {
        display: flex; }
        .style_container__isTop__item__2koAI:first-child ul li, .style_container__isTop__item__2koAI:nth-child(2) ul li {
          margin-right: 20px;
          opacity: 0.8; }
          .style_container__isTop__item__2koAI:first-child ul li a, .style_container__isTop__item__2koAI:nth-child(2) ul li a {
            font-size: 1.3rem; }
    .style_container__isTop__item__2koAI h6 {
      margin: 0;
      font-size: 0.9rem;
      margin-bottom: 10px;
      opacity: 0.8; }
      .style_container__isTop__item__2koAI h6 a {
        color: #ffffff; }
    .style_container__isTop__item__2koAI ul {
      list-style: none;
      padding: 0; }
      .style_container__isTop__item__2koAI ul li {
        opacity: 0.5;
        margin-bottom: 10px; }
        .style_container__isTop__item__2koAI ul li:hover {
          opacity: 0.8; }
        .style_container__isTop__item__2koAI ul li a {
          color: #ffffff;
          font-size: 0.7rem;
          font-weight: 600; }
    .style_container__isTop__item__2koAI p {
      font-size: 0.8rem;
      opacity: 0.8;
      line-height: 20px; }

.style_container__isBottom__1-pUR {
  background: #072428;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  padding: 10px 10%;
  align-items: baseline; }
  @media (max-width: 425px) {
    .style_container__isBottom__1-pUR {
      flex-direction: column-reverse; } }
  .style_container__isBottom__1-pUR p {
    margin: 0;
    font-size: 0.6rem;
    opacity: 0.5; }
  .style_container__isBottom__1-pUR ul {
    list-style: none;
    padding: 0;
    display: flex; }
    @media (max-width: 425px) {
      .style_container__isBottom__1-pUR ul {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px; } }
    .style_container__isBottom__1-pUR ul li {
      opacity: 0.5;
      margin-left: 10px; }
      @media (max-width: 425px) {
        .style_container__isBottom__1-pUR ul li {
          margin-left: 0px; } }
      .style_container__isBottom__1-pUR ul li a,
      .style_container__isBottom__1-pUR ul li span {
        text-decoration: underline;
        color: #ffffff;
        font-size: 0.6rem;
        cursor: pointer; }

#mj-w-overlay #mj-w-close-img {
  position: absolute;
  right: 5px !important;
  top: 5px !important; }

ul {
  margin: 0; }

.testimonial-card-content {
  display: flex;
  align-items: center; }
  @media (min-width: 769px) {
    .testimonial-card-content {
      flex-direction: row;
      justify-content: center;
      max-width: 65vw;
      margin: 0 auto; } }
  @media (max-width: 768px) {
    .testimonial-card-content {
      height: auto;
      flex-direction: column; } }
  .testimonial-card-content .testimonial-card-image-container {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-size: cover;
    background-position: center; }
    @media (max-width: 768px) {
      .testimonial-card-content .testimonial-card-image-container {
        margin-bottom: 20px; } }
    @media (max-width: 425px) {
      .testimonial-card-content .testimonial-card-image-container {
        height: 75px;
        width: 75px;
        border-radius: 50px; } }
  .testimonial-card-content .testimonial-card-info-container {
    margin-left: 20px;
    max-width: 55vw; }
  .testimonial-card-content .testimonial-card-subtitle {
    opacity: 0.6;
    font-size: 0.8rem;
    margin-top: 2px; }
    @media (max-width: 768px) {
      .testimonial-card-content .testimonial-card-subtitle {
        font-size: 0.7rem;
        text-align: center; } }
    @media (max-width: 425px) {
      .testimonial-card-content .testimonial-card-subtitle {
        font-size: 0.7rem; } }
  .testimonial-card-content .testimonial-card-description {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    text-align: center; }
    @media (max-width: 768px) {
      .testimonial-card-content .testimonial-card-description {
        font-size: 0.8rem; } }
    @media (max-width: 425px) {
      .testimonial-card-content .testimonial-card-description {
        font-size: 0.7rem; } }
    @media (min-width: 769px) {
      .testimonial-card-content .testimonial-card-description {
        font-size: 0.9rem;
        text-align: left;
        padding-left: 0px;
        margin-bottom: 10px; } }

ul {
  margin: 0; }

.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.home-header {
  position: relative;
  overflow: hidden; }
  .home-header .home-header-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .home-header .home-header-heading {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 10px;
    color: #2095a9;
    text-align: center; }
    @media (max-width: 425px) {
      .home-header .home-header-heading {
        font-size: 2rem; } }
  .home-header svg {
    margin: 25px; }
  .home-header .home-header-subHeader {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 5px;
    text-align: center;
    line-height: 1.5rem;
    margin-bottom: 20px;
    min-height: 1px; }
    @media (max-width: 425px) {
      .home-header .home-header-subHeader {
        font-size: 1rem;
        line-height: 1rem; } }
    .home-header .home-header-subHeader .home-header-img-container {
      margin: 0 auto;
      margin-top: 10px;
      max-width: 400px;
      width: 40%;
      box-sizing: border-box; }
  .home-header .home-header-button {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.2rem; }
    @media (max-width: 425px) {
      .home-header .home-header-button {
        font-size: 1rem; } }

.home-description {
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 1200px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 1px;
  width: 100%; }
  @media (max-width: 768px) {
    .home-description {
      margin-bottom: 15px;
      margin-left: 15px;
      margin-right: 15px;
      padding: 0;
      width: auto; } }
  .home-description .home-description-content {
    flex-direction: column;
    align-items: center; }
    @media (max-width: 768px) {
      .home-description .home-description-content {
        flex-direction: column;
        align-items: center; } }
  .home-description .home-description-heading {
    margin-top: 5px;
    font-weight: 700;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    font-size: 2.5rem; }
    @media (max-width: 768px) {
      .home-description .home-description-heading {
        font-size: 2rem;
        text-align: center; } }
  .home-description .home-description-desktop-row-wraper {
    display: flex;
    justify-content: center; }
  .home-description .home-description-rows {
    flex: 11 1;
    margin-top: 50px; }
    @media (max-width: 768px) {
      .home-description .home-description-rows {
        margin-top: 30px; } }
    @media (max-width: 425px) {
      .home-description .home-description-rows {
        margin-top: 15px; } }
    .home-description .home-description-rows .home-description-row {
      display: flex; }
      .home-description .home-description-rows .home-description-row:not(:first-child) {
        margin-top: 30px; }
        @media (min-width: 769px) {
          .home-description .home-description-rows .home-description-row:not(:first-child) {
            margin-top: 30px; } }
      .home-description .home-description-rows .home-description-row .home-description-row-icon {
        flex: 1 1 auto;
        font-size: 20px; }
        @media (min-width: 769px) {
          .home-description .home-description-rows .home-description-row .home-description-row-icon {
            font-size: 30px; } }
      .home-description .home-description-rows .home-description-row .home-description-row-text {
        font-size: 1rem;
        text-align: justify;
        line-height: 25px; }
        @media (min-width: 769px) {
          .home-description .home-description-rows .home-description-row .home-description-row-text {
            flex: 10 1;
            padding-left: 40px;
            padding-right: 40px; } }
        .home-description .home-description-rows .home-description-row .home-description-row-text .subinfo {
          margin-top: 10px;
          font-size: 12px;
          opacity: 0.6; }
          @media (max-width: 768px) {
            .home-description .home-description-rows .home-description-row .home-description-row-text .subinfo {
              font-size: 10px; } }
          @media (max-width: 425px) {
            .home-description .home-description-rows .home-description-row .home-description-row-text .subinfo {
              font-size: 8px; } }
  .home-description .home-description-proximag-container {
    margin-top: 50px;
    flex: 4 1; }
    .home-description .home-description-proximag-container .home-description-proximag-mag {
      width: 100%; }
      .home-description .home-description-proximag-container .home-description-proximag-mag img {
        width: 100%; }
    .home-description .home-description-proximag-container .home-description-proximag-button {
      margin-top: 20px; }
      .home-description .home-description-proximag-container .home-description-proximag-button a {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2095a9;
        padding: 10px 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        outline: none;
        text-transform: inherit;
        font-size: 1rem;
        font-family: "Montserrat", sans-serif;
        width: 100%;
        color: #ffffff;
        font-weight: 600; }
        .home-description .home-description-proximag-container .home-description-proximag-button a:hover {
          opacity: 0.8;
          -webkit-transition: opacity 0.1s ease-in-out;
          transition: opacity 0.1s ease-in-out; }

.home-tutorial-container {
  height: auto;
  width: 100vw;
  background: #f9f9f9;
  padding: 50px; }
  @media (max-width: 425px) {
    .home-tutorial-container {
      padding: 15px; } }
  .home-tutorial-container .home-tutorial-title {
    margin-bottom: 50px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    font-size: 2.5rem; }
    @media (max-width: 425px) {
      .home-tutorial-container .home-tutorial-title {
        font-size: 2rem;
        text-align: center; } }
  .home-tutorial-container .home-tutorial-wrapper {
    max-width: 900px;
    margin: 0 auto; }
    .home-tutorial-container .home-tutorial-wrapper .home-tutorial-video {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden; }
      .home-tutorial-container .home-tutorial-wrapper .home-tutorial-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

.home-middle {
  margin-top: 50px;
  position: relative;
  max-height: 400px;
  overflow: hidden; }
  @media (max-width: 425px) {
    .home-middle {
      margin-top: 20px;
      margin-bottom: 20px; } }
  .home-middle .home-middle-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .home-middle .home-middle-content .home-middle-heading {
      font-size: 2.5rem;
      font-weight: 700;
      margin-top: 5px;
      margin-bottom: 5px; }
      @media (max-width: 425px) {
        .home-middle .home-middle-content .home-middle-heading {
          font-size: 2rem;
          text-align: center;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 15px; } }
  .home-middle .home-middle-button {
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 1.2rem; }
    @media (max-width: 425px) {
      .home-middle .home-middle-button {
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 1rem; } }

.home-client-counter {
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  width: 100%; }
  .home-client-counter .home-client-counter-content {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 769px) {
      .home-client-counter .home-client-counter-content {
        height: 50px;
        flex-direction: row;
        justify-content: space-evenly; } }
    .home-client-counter .home-client-counter-content .home-client-counter-row {
      display: flex;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-left: 10px;
      margin-right: 10px; }
      .home-client-counter .home-client-counter-content .home-client-counter-row .icon {
        display: flex;
        align-items: center;
        font-size: 20px; }
      .home-client-counter .home-client-counter-content .home-client-counter-row .text {
        display: flex;
        align-items: center;
        padding-left: 5px;
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px); }
        .home-client-counter .home-client-counter-content .home-client-counter-row .text b {
          margin-right: 3px; }
        @media (max-width: 768px) {
          .home-client-counter .home-client-counter-content .home-client-counter-row .text {
            margin-top: 3px;
            margin-bottom: 3px; } }
        @media (max-width: 425px) {
          .home-client-counter .home-client-counter-content .home-client-counter-row .text {
            font-size: 0.8rem; } }

.aws-sld {
  height: 300px;
  display: flex;
  z-index: 0; }
  .aws-sld .aws-sld__content {
    background: white;
    padding: 10px; }
  @media (max-width: 768px) {
    .aws-sld .aws-sld__next,
    .aws-sld .aws-sld__prev {
      display: none; } }
  .aws-sld .aws-sld__bullets {
    display: none; }
    @media (max-width: 768px) {
      .aws-sld .aws-sld__bullets {
        display: block;
        display: flex;
        margin-bottom: 50px;
        z-index: 100; }
        .aws-sld .aws-sld__bullets button {
          width: 5px;
          height: 5px; } }

img.middle-image-scale {
  max-width: 140%;
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0.25; }
  @media (min-width: 769px) {
    img.middle-image-scale {
      width: 100%; } }
  @media (max-width: 768px) {
    img.middle-image-scale {
      max-width: 160%; } }
  @media (max-width: 425px) {
    img.middle-image-scale {
      max-width: 140%; } }

img.scale {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0.25; }

.alice-carousel {
  margin-top: 20px;
  margin-bottom: 20px; }
  .alice-carousel .alice-carousel__dots-item {
    background: #aaaaaa; }
  .alice-carousel .alice-carousel__dots-item.__active {
    background: #2095a9; }

ul {
  margin: 0; }

#root {
  min-height: 100vh; }
  #root .page-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; }
  #root .App {
    height: 100%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      #root .App #header {
        position: fixed;
        top: 0px;
        z-index: 1;
        height: 50px;
        width: 100%; } }
    #root .App #content {
      flex: 1 1 auto;
      min-height: 1px; }
      @media (max-width: 425px) {
        #root .App #content {
          margin-top: 50px; } }
    #root .App #footer {
      flex-shrink: 0; }

.leaflet-container {
  height: 400px;
  width: 600px;
  z-index: 0; }
  @media (max-width: 768px) {
    .leaflet-container {
      width: 80vw;
      height: 200px; } }

ul {
  margin: 0; }

#root {
  min-height: 100vh; }
  #root .page-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; }
  #root .App {
    height: 100%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      #root .App #header {
        position: fixed;
        top: 0px;
        z-index: 1;
        height: 50px;
        width: 100%; } }
    #root .App #content {
      flex: 1 1 auto;
      min-height: 1px; }
      @media (max-width: 425px) {
        #root .App #content {
          margin-top: 50px; } }
    #root .App #footer {
      flex-shrink: 0; }

@media (max-width: 768px) {
  .signUp--input-container {
    padding-left: 10px;
    margin-right: 10px; } }

@media (max-width: 425px) {
  .signUp--input-container {
    padding-left: 0px;
    margin-right: 0px; } }

.signUp--input-container label {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.2px; }

.signUp--input-container .signUp--input-wrapper input,
.signUp--input-container .signUp--input-wrapper textarea {
  width: 100%;
  margin-top: 30px;
  outline: none;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #ebebeb;
  font-size: 1rem;
  outline: none;
  border-radius: 0px;
  background: transparent; }

.signUp--input-container .signUp--input-wrapper textarea {
  border: 1px solid #ebebeb;
  font-family: "Montserrat", sans-serif;
  height: 200px; }

.signUp--input-container .signUp--input-wrapper select {
  width: 100%;
  margin-top: 20px;
  outline: none;
  margin-bottom: 30px;
  border: none;
  background: transparent; }

.signUp--input-container .signUp--input-wrapper span {
  color: #e93838;
  border-top: 1px solid #e93838;
  width: 100%;
  display: block;
  margin-top: -30px;
  margin-bottom: 20px;
  font-size: 0.8rem;
  padding-top: 10px;
  font-weight: 600; }

ul {
  margin: 0; }

.contact-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center; }
  .contact-container .contact-content-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px; }
    @media (max-width: 768px) {
      .contact-container .contact-content-container {
        flex-direction: column-reverse;
        margin-bottom: 10px; } }
    .contact-container .contact-content-container .contact-content-info {
      background: #f9f9f9;
      padding: 20px;
      color: #0b0b0b; }
      @media (max-width: 768px) {
        .contact-container .contact-content-container .contact-content-info {
          text-align: center; } }
      .contact-container .contact-content-container .contact-content-info h6 {
        font-size: 1rem;
        letter-spacing: 0.2px; }
        @media (max-width: 768px) {
          .contact-container .contact-content-container .contact-content-info h6 {
            margin: 0;
            margin-top: 10px; } }
      .contact-container .contact-content-container .contact-content-info p {
        font-size: 0.9rem; }
        .contact-container .contact-content-container .contact-content-info p:last-child {
          font-size: 0.8rem;
          margin-top: 40px; }
  .contact-container .contact-form-container {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 50px; }
    .contact-container .contact-form-container h6 {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 20px; }
      @media (max-width: 425px) {
        .contact-container .contact-form-container h6 {
          font-size: 2rem; } }
    .contact-container .contact-form-container .form-container--row {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 768px) {
        .contact-container .contact-form-container .form-container--row {
          flex-direction: column; } }
      .contact-container .contact-form-container .form-container--row .signUp--input-container {
        width: 48%; }
        @media (max-width: 768px) {
          .contact-container .contact-form-container .form-container--row .signUp--input-container {
            width: unset; } }
    .contact-container .contact-form-container .text-button-container {
      width: 180px;
      margin: 0 auto;
      margin-top: 30px; }

.contact-confirm-message {
  text-align: center;
  margin-top: 40px;
  border: 2px solid #2095a9;
  padding: 30px;
  color: #2095a9; }
  .contact-confirm-message h5 {
    margin: 0; }

ul {
  margin: 0; }

.faq-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-left: 50px;
  margin-right: 50px; }
  @media (max-width: 425px) {
    .faq-container {
      margin: 10px; } }
  .faq-container .faq-wrapper {
    margin-bottom: 40px; }
    @media (max-width: 425px) {
      .faq-container .faq-wrapper {
        margin-bottom: 20px; } }
    .faq-container .faq-wrapper .faq-question-wrapper {
      color: #0b0b0b;
      font-weight: bold;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .faq-container .faq-wrapper .faq-question-wrapper:hover {
        color: #2095a9; }
      @media (max-width: 425px) {
        .faq-container .faq-wrapper .faq-question-wrapper {
          font-size: 1rem; } }
    .faq-container .faq-wrapper .faq-answer-wrapper {
      display: none;
      font-size: 1.1rem;
      margin-top: 10px; }
      @media (max-width: 425px) {
        .faq-container .faq-wrapper .faq-answer-wrapper {
          margin: 0;
          margin-top: 10px;
          font-size: 0.9rem; } }
    .faq-container .faq-wrapper .faq-answer-wrapper--open {
      display: block; }
  .faq-container .faq-tooltip {
    color: #2095a9;
    font-weight: 600;
    margin-top: 30px; }
    @media (max-width: 425px) {
      .faq-container .faq-tooltip {
        font-size: 0.9rem; } }
    .faq-container .faq-tooltip p {
      margin: 2px; }
      .faq-container .faq-tooltip p a {
        color: #2095a9;
        text-decoration: underline; }
        .faq-container .faq-tooltip p a:hover {
          opacity: 0.8; }

ul {
  margin: 0; }

.style_container__1NgCW {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  margin-top: 20px; }
  .style_container__button__3fk9R {
    text-decoration: underline;
    cursor: pointer;
    outline: none; }
    .style_container__button__3fk9R:hover {
      opacity: 0.8; }

ul {
  margin: 0; }

.signUp-container {
  min-height: 50vh; }
  .signUp-container .signUp-container--message {
    display: block;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #2095a9;
    color: white; }
  .signUp-container .signUp-container--header {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 50px; }
    @media (max-width: 425px) {
      .signUp-container .signUp-container--header {
        margin-bottom: 20px; } }
  .signUp-container .form-hidden {
    display: none; }
  .signUp-container form {
    width: 100%; }
    @media (max-width: 425px) {
      .signUp-container form {
        margin-top: 30px; } }
    .signUp-container form .signUp_reset {
      color: #2095a9;
      text-align: center;
      font-size: 0.8rem;
      margin-bottom: 20px;
      font-weight: 600;
      cursor: pointer; }
      .signUp-container form .signUp_reset:hover {
        opacity: 0.7; }
    .signUp-container form .signUp-container--row {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 768px) {
        .signUp-container form .signUp-container--row {
          flex-direction: column; } }
      .signUp-container form .signUp-container--row .signUp--input-container {
        width: 48%; }
        @media (max-width: 768px) {
          .signUp-container form .signUp-container--row .signUp--input-container {
            width: unset; } }
    .signUp-container form .signUp-container--row_3-4 {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 768px) {
        .signUp-container form .signUp-container--row_3-4 {
          flex-direction: column; } }
      .signUp-container form .signUp-container--row_3-4 .signUp--input-container:first-child {
        flex: 4 1;
        margin-right: 10px; }
      .signUp-container form .signUp-container--row_3-4 .signUp--input-container:last-child {
        flex: 1 1 auto; }
  .signUp-container .signUp-buttons--row {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    @media (max-width: 425px) {
      .signUp-container .signUp-buttons--row {
        flex-direction: column-reverse; } }
    .signUp-container .signUp-buttons--row .text-button-container {
      margin: 10px; }

ul {
  margin: 0; }

.dropdown-container {
  width: 100%; }
  .dropdown-container .dropdown-content {
    width: 100%; }
    .dropdown-container .dropdown-content .dropdown-header {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600; }
      .dropdown-container .dropdown-content .dropdown-header.standalone {
        color: white;
        background-color: #2095a9;
        font-size: 20px;
        height: 55px;
        padding: 20px 10px; }
      .dropdown-container .dropdown-content .dropdown-header.open {
        background-color: #2095a9; }
      .dropdown-container .dropdown-content .dropdown-header.closed {
        background-color: #2095a9;
        opacity: 0.5; }
      .dropdown-container .dropdown-content .dropdown-header.part-of-form {
        background-color: white;
        border: 2px solid black;
        font-size: 14px;
        line-height: 37px; }
        @media (max-width: 425px) {
          .dropdown-container .dropdown-content .dropdown-header.part-of-form {
            line-height: 26px; } }
      .dropdown-container .dropdown-content .dropdown-header.red {
        color: red;
        border: 2px solid red; }
      @media (max-width: 425px) {
        .dropdown-container .dropdown-content .dropdown-header .header-title {
          font-size: 14px; } }
      .dropdown-container .dropdown-content .dropdown-header .header-title.part-of-form {
        color: #727272;
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px); }
        @media (max-width: 425px) {
          .dropdown-container .dropdown-content .dropdown-header .header-title.part-of-form {
            line-height: 18px;
            font-size: 12px; } }
        .dropdown-container .dropdown-content .dropdown-header .header-title.part-of-form.value-selected {
          color: black; }
        .dropdown-container .dropdown-content .dropdown-header .header-title.part-of-form.red {
          color: red; }
      .dropdown-container .dropdown-content .dropdown-header .header-icon {
        padding-right: 8px; }
        .dropdown-container .dropdown-content .dropdown-header .header-icon.open {
          -webkit-transition: 0.9s;
          transition: 0.9s;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }
      .dropdown-container .dropdown-content .dropdown-header:hover {
        cursor: pointer; }
      @media (max-width: 768px) {
        .dropdown-container .dropdown-content .dropdown-header {
          font-size: 16px; } }
    .dropdown-container .dropdown-content .dropdown-children {
      width: 100%;
      background-color: #f9f9f9; }
      .dropdown-container .dropdown-content .dropdown-children.overlay {
        position: absolute;
        width: 100%;
        z-index: 1; }
  .dropdown-container .dropdown-error-placeholder {
    color: red;
    display: flex;
    align-items: flex-start;
    height: 15px;
    padding-left: 5px; }
    .dropdown-container .dropdown-error-placeholder .text-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px; }

ul {
  margin: 0; }

.search-input-container .input-container {
  display: flex;
  flex-direction: column; }
  .search-input-container .input-container input {
    padding: 10px;
    border: 2px solid black; }
    @media (max-width: 425px) {
      .search-input-container .input-container input {
        line-height: 22px;
        font-size: 12px;
        border: 1px solid black;
        margin: 0 auto; } }
    .search-input-container .input-container input.text-input-err {
      border: 2px solid red;
      color: #e93838; }
      .search-input-container .input-container input.text-input-err::-webkit-input-placeholder {
        color: #e93838; }
      .search-input-container .input-container input.text-input-err::-moz-placeholder {
        color: #e93838; }
      .search-input-container .input-container input.text-input-err:-ms-input-placeholder {
        color: #e93838; }
      .search-input-container .input-container input.text-input-err::-ms-input-placeholder {
        color: #e93838; }
      .search-input-container .input-container input.text-input-err::placeholder {
        color: #e93838; }
      .search-input-container .input-container input.text-input-err::-ms-input-placeholder {
        color: #e93838; }
    .search-input-container .input-container input:focus {
      outline: none; }
  .search-input-container .input-container .text-input-error-placeholder {
    color: red;
    display: flex;
    align-items: flex-start;
    height: 15px;
    padding-left: 5px; }
    @media (max-width: 425px) {
      .search-input-container .input-container .text-input-error-placeholder {
        height: 4px;
        justify-content: flex-end; } }
    .search-input-container .input-container .text-input-error-placeholder .text-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px; }
      @media (max-width: 425px) {
        .search-input-container .input-container .text-input-error-placeholder .text-input-error {
          font-size: 8px; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

ul {
  margin: 0; }

.style_container__378eG {
  background: #f9f9f9; }
  .style_container__item__20EcX {
    margin-bottom: 10px;
    padding: 15px 10px;
    color: white;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    outline: none; }

ul {
  margin: 0; }

.style_container__CpjPb {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
  outline: none; }
  .style_container__check__sNARq {
    margin-right: 5px; }
  @media (max-width: 425px) {
    .style_container__title__2gicR {
      font-size: 0.8rem; } }
  .style_container__title__2gicR span {
    text-decoration: underline; }
    .style_container__title__2gicR span:hover {
      opacity: 0.7; }

ul {
  margin: 0; }

.editions-container {
  height: 100%;
  margin: 0 auto; }
  .editions-container .editions-search {
    display: flex;
    justify-content: center;
    margin-top: -25px; }
    @media (min-width: 769px) {
      .editions-container .editions-search {
        padding-top: 10px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 20px; } }
    @media (max-width: 768px) {
      .editions-container .editions-search {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 425px) {
      .editions-container .editions-search {
        padding: 10px 0; } }
    .editions-container .editions-search .editions-search-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      @media (max-width: 768px) {
        .editions-container .editions-search .editions-search-content {
          align-items: flex-start; } }
      @media (max-width: 425px) {
        .editions-container .editions-search .editions-search-content {
          display: flex;
          align-items: stretch; } }
      .editions-container .editions-search .editions-search-content .search-title {
        display: flex;
        justify-content: center; }
        .editions-container .editions-search .editions-search-content .search-title .text-button-container {
          color: white;
          max-width: 189px;
          margin: 0 auto;
          margin-bottom: 20px; }
        @media (min-width: 769px) {
          .editions-container .editions-search .editions-search-content .search-title {
            font-size: 1.2rem;
            max-width: 450px;
            text-align: center;
            margin-bottom: 25px; } }
        @media (max-width: 768px) {
          .editions-container .editions-search .editions-search-content .search-title {
            flex: 3 1; }
            .editions-container .editions-search .editions-search-content .search-title .search-title-content {
              width: 90%; } }
        @media (max-width: 425px) {
          .editions-container .editions-search .editions-search-content .search-title {
            font-size: 12px;
            margin-top: 20px; }
            .editions-container .editions-search .editions-search-content .search-title .search-title-content {
              width: 100%;
              margin-bottom: 10px; } }
      .editions-container .editions-search .editions-search-content .search-input {
        display: flex;
        justify-content: center; }
        @media (max-width: 768px) {
          .editions-container .editions-search .editions-search-content .search-input {
            margin: 0 auto; } }
        @media (min-width: 769px) {
          .editions-container .editions-search .editions-search-content .search-input {
            width: 300px; }
            .editions-container .editions-search .editions-search-content .search-input .search-input-content {
              width: 90%; } }
        @media (max-width: 768px) {
          .editions-container .editions-search .editions-search-content .search-input .search-input-content {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px; } }
        .editions-container .editions-search .editions-search-content .search-input .dropdown {
          position: relative;
          width: 100%; }
        .editions-container .editions-search .editions-search-content .search-input .error {
          text-align: center;
          margin-top: 2px;
          height: 15px;
          font-size: 10px;
          color: #e93838; }
          @media (min-width: 769px) {
            .editions-container .editions-search .editions-search-content .search-input .error {
              display: none; } }
  .editions-container .editions-accordeon-dates-pricing {
    margin-bottom: 30px; }
    @media (max-width: 425px) {
      .editions-container .editions-accordeon-dates-pricing {
        padding: 0;
        margin-top: 20px; } }
    .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content {
      display: flex;
      justify-content: center; }
      @media (max-width: 768px) {
        .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content {
          display: flex;
          flex-direction: column;
          justify-content: center; } }
      .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates {
        flex: 1 1; }
        .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content {
          display: flex;
          justify-content: center;
          flex-direction: column; }
          @media (max-width: 768px) {
            .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content {
              flex-direction: column; } }
          .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .accordeon {
            flex: 1 1 auto;
            display: flex;
            justify-content: center; }
            @media (max-width: 768px) {
              .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .accordeon {
                flex: 1 1 auto;
                margin-bottom: 0px; } }
            .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .accordeon .accordeon-content {
              width: 90%; }
              @media (max-width: 425px) {
                .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .accordeon .accordeon-content {
                  width: 100%; } }
            .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .accordeon .dropdown-container:not(:first-child) {
              margin-top: 10px; }
          .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates {
            display: flex;
            justify-content: center;
            flex: 1 1 auto;
            margin-top: 30px; }
            @media (max-width: 768px) {
              .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates {
                margin-bottom: 40px; } }
            .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content {
              width: 90%;
              height: -webkit-fit-content;
              height: -moz-fit-content;
              height: fit-content;
              background: #f9f9f9; }
              .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content p {
                padding: 1em;
                margin: 0; }
              @media (max-width: 425px) {
                .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content {
                  width: 100%; } }
              .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content .dates-header {
                background-color: #2095a9;
                color: white;
                padding: 15px 10px;
                font-size: 1rem;
                font-weight: 600;
                display: flex;
                align-items: center; }
                @media (max-width: 425px) {
                  .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content .dates-header {
                    font-size: 16px; } }
              .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content .dates-loading {
                padding-top: 40px;
                padding-bottom: 40px;
                display: flex;
                justify-content: center;
                align-items: center; }
              .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content .dates-list {
                background-color: beige; }
                .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content .dates-list .dates-error {
                  padding-top: 10px;
                  padding-bottom: 10px;
                  padding-left: 5px;
                  margin-top: 5px;
                  font-size: 12px;
                  color: #e93838; }
                .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .accordeon-dates .accordeon-dates-content .dates .dates-content .dates-list .dates-placeholder {
                  text-align: center;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  font-size: 11px; }
      .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .order-help {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1;
        max-width: 450px; }
        @media (max-width: 768px) {
          .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .order-help {
            flex: 2 1; } }
        .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .order-help .order-help-content {
          width: 90%; }
          @media (max-width: 425px) {
            .editions-container .editions-accordeon-dates-pricing .editions-accordeon-dates-pricing-content .order-help .order-help-content {
              width: 100%; } }
  .editions-container .editions-search-dropdown-option {
    padding: 5px; }
    .editions-container .editions-search-dropdown-option:hover {
      cursor: pointer;
      background-color: #ffcccb;
      color: white;
      font-weight: 600; }
    .editions-container .editions-search-dropdown-option:active {
      background-color: #ffcccb; }
    .editions-container .editions-search-dropdown-option.selected {
      background-color: #ffcccb; }
  .editions-container .editions-mention {
    text-align: center; }

ul {
  margin: 0; }

.checkbox-container .checkbox-content {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .checkbox-container .checkbox-content.centerText {
    justify-content: space-between; }
  @media (max-width: 425px) {
    .checkbox-container .checkbox-content {
      height: 24px;
      font-size: 12px; } }
  .checkbox-container .checkbox-content:hover {
    cursor: pointer; }
  .checkbox-container .checkbox-content .checkContainer {
    display: flex;
    align-items: center;
    color: white; }
    .checkbox-container .checkbox-content .checkContainer.centerText {
      width: 15%; }
    .checkbox-container .checkbox-content .checkContainer .checkbox {
      padding-left: 5px;
      padding-right: 5px; }
  .checkbox-container .checkbox-content .textContainer {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; }
    .checkbox-container .checkbox-content .textContainer.textLink {
      text-decoration: underline;
      color: blue; }
    .checkbox-container .checkbox-content .textContainer.centerText {
      padding-right: 6px;
      padding-left: 3px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.8rem;
      width: 100%; }
  .checkbox-container .checkbox-content .placeHolder {
    height: 10px;
    width: 15%; }

ul {
  margin: 0; }

.formatSelection-container {
  flex: 1 1 auto; }
  .formatSelection-container .formatSelection-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none; }
    .formatSelection-container .formatSelection-content:hover {
      cursor: pointer;
      -webkit-transform: scale(1.02, 1.02);
              transform: scale(1.02, 1.02); }
    .formatSelection-container .formatSelection-content.selected {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
      outline: none; }
    .formatSelection-container .formatSelection-content .formatSelection-image {
      width: 100%; }
      .formatSelection-container .formatSelection-content .formatSelection-image img {
        width: 100%; }
    .formatSelection-container .formatSelection-content .formatSelection-footer {
      width: 97%;
      background-color: #aaaaaa;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      padding: 5px 10px; }

ul {
  margin: 0; }

.format-container {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 425px) {
    .format-container {
      padding: 0; } }
  .format-container .format-subtitle {
    display: flex;
    justify-content: center;
    width: 100%; }
    .format-container .format-subtitle .format-subtitle-content {
      font-size: 1.2rem;
      max-width: 600px;
      text-align: center;
      margin-bottom: 50px; }
      @media (max-width: 425px) {
        .format-container .format-subtitle .format-subtitle-content {
          flex-direction: column;
          text-align: justify;
          font-size: 14px;
          margin-bottom: 20px; } }
  .format-container .format-main {
    width: 100%;
    display: flex;
    justify-content: space-evenly; }
    .format-container .format-main .format-main-content {
      width: 100%;
      display: flex;
      justify-content: center; }
      @media (max-width: 768px) {
        .format-container .format-main .format-main-content {
          flex-direction: column; } }
      .format-container .format-main .format-main-content .formats {
        flex: 1 1; }
        @media (max-width: 768px) {
          .format-container .format-main .format-main-content .formats {
            flex: 1 1; } }
        .format-container .format-main .format-main-content .formats .formats-content {
          display: flex;
          width: 100%; }
          @media (min-width: 769px) {
            .format-container .format-main .format-main-content .formats .formats-content {
              justify-content: center; } }
          @media (max-width: 768px) {
            .format-container .format-main .format-main-content .formats .formats-content {
              flex-direction: column;
              align-items: center; } }
          .format-container .format-main .format-main-content .formats .formats-content .format-category {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px; }
            .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content {
              margin-right: 20px;
              width: 100%;
              background-color: #f9f9f9;
              height: -webkit-fit-content;
              height: -moz-fit-content;
              height: fit-content; }
              @media (max-width: 768px) {
                .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content {
                  margin: 0; } }
              .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content .header {
                text-transform: capitalize;
                background-color: #2095a9;
                color: white;
                padding: 15px 10px;
                font-size: 18px;
                display: flex;
                align-items: center;
                font-weight: 600; }
                @media (max-width: 425px) {
                  .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content .header {
                    font-size: 16px; } }
              .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content .formats-list {
                padding: 10px 0px 10px 0px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                max-width: 460px;
                margin: 0 auto; }
                .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content .formats-list .format-item {
                  flex: 1 1 auto;
                  min-width: 165px;
                  max-width: 215px;
                  padding: 10px 4px 10px 4px; }
                  @media (max-width: 425px) {
                    .format-container .format-main .format-main-content .formats .formats-content .format-category .format-category-content .formats-list .format-item {
                      min-width: 150px;
                      max-width: 120px; } }
      .format-container .format-main .format-main-content .order-help {
        flex: 1 1;
        margin-bottom: 20px;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media (max-width: 768px) {
          .format-container .format-main .format-main-content .order-help {
            flex: 2 1; } }
        .format-container .format-main .format-main-content .order-help .order-help-content {
          width: 100%; }
          @media (max-width: 425px) {
            .format-container .format-main .format-main-content .order-help .order-help-content {
              width: 100%; } }
  .format-container .format-mention {
    text-align: center; }

ul {
  margin: 0; }

.style_container__3z2Vp {
  color: white;
  width: 35px;
  height: 31px;
  background-color: #2095a9;
  display: flex;
  justify-content: center;
  align-items: center; }
  .style_container__3z2Vp:hover {
    background-color: #ffcccb;
    cursor: pointer; }

ul {
  margin: 0; }

.upload-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2095a9;
  padding: 5px 10px;
  outline: none; }
  .upload-button-container.rounded {
    border-radius: 10px; }
  .upload-button-container.fit {
    flex: 1 1 auto; }
  .upload-button-container:hover {
    cursor: pointer;
    opacity: 0.8; }
  .upload-button-container:active {
    background-color: #ffcccb; }
  .upload-button-container .text-button-text {
    color: white;
    font-weight: 600; }

ul {
  margin: 0; }

.file-input-container {
  width: 100%; }
  .file-input-container .file-input-label {
    width: 100%; }
    .file-input-container .file-input-label .file-input-label-content {
      display: flex;
      align-items: center;
      border: 1.5px solid #0b0b0b;
      background-color: white;
      padding: 3px; }
      .file-input-container .file-input-label .file-input-label-content .file-input-text {
        margin: 5px 2px 5px 2px;
        height: 21px;
        flex: 1 1 auto;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 5px 0px 5px; }
        @media (max-width: 425px) {
          .file-input-container .file-input-label .file-input-label-content .file-input-text {
            height: 16px;
            font-size: 11px; } }
      .file-input-container .file-input-label .file-input-label-content.err {
        color: #e93838;
        border: 2px solid #e93838; }
    .file-input-container .file-input-label .file-input-button {
      flex-shrink: 1; }
      @media (max-width: 425px) {
        .file-input-container .file-input-label .file-input-button {
          font-size: 10px; } }
  .file-input-container input {
    display: none; }
    .file-input-container input:focus {
      outline: none; }
  .file-input-container .file-input-error-placeholder {
    color: red;
    display: flex;
    align-items: flex-start;
    height: 16px;
    padding-left: 12px; }
    .file-input-container .file-input-error-placeholder .file-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

ul {
  margin: 0; }

@media (max-width: 768px) {
  .style_container__1HkqT {
    font-size: 14px; } }

ul {
  margin: 0; }

.style_label__39WN8 {
  display: flex;
  flex-direction: column;
  width: 99%;
  margin-bottom: 30px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.2px; }
  .style_label__39WN8 input,
  .style_label__39WN8 textarea {
    background: transparent;
    border: none;
    border-bottom: 1px solid #606060;
    margin-top: 20px;
    outline: none; }
  .style_label__39WN8 textarea {
    border: 1px solid #606060;
    height: 150px; }
  .style_label__39WN8 span {
    color: #e93838;
    border-top: 1px solid #e93838;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    font-size: 0.8rem;
    padding-top: 10px;
    font-weight: 600;
    margin-top: -1px; }
  .style_label__39WN8:last-child {
    margin-bottom: 0px; }

ul {
  margin: 0; }

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; }
  @media (max-width: 425px) {
    .upload-container {
      padding: 0px 10px 0px 10px; } }
  .upload-container .loading-container {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .upload-container .upload-header {
    position: relative;
    overflow: hidden; }
    .upload-container .upload-header img.scale {
      width: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      opacity: 0.25; }
  .upload-container .upload-subtitle {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    width: 100%; }
    .upload-container .upload-subtitle .upload-subtitle-content {
      font-size: 1.2rem;
      max-width: 600px;
      text-align: center;
      margin-bottom: 50px; }
      @media (max-width: 425px) {
        .upload-container .upload-subtitle .upload-subtitle-content {
          flex-direction: column;
          text-align: justify;
          font-size: 14px;
          margin-bottom: 20px; } }
  .upload-container .upload-main {
    display: flex;
    width: 100%;
    margin-right: 20px; }
    @media (max-width: 768px) {
      .upload-container .upload-main {
        flex-direction: column;
        margin: 0; } }
    .upload-container .upload-main .solutions-container {
      flex: 1 1; }
      @media (min-width: 769px) {
        .upload-container .upload-main .solutions-container {
          display: flex;
          flex-direction: column; } }
      .upload-container .upload-main .solutions-container .solution-type-container {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        background-color: #f9f9f9;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
        flex-direction: column;
        outline: none; }
        @media (max-width: 768px) {
          .upload-container .upload-main .solutions-container .solution-type-container {
            margin: 0;
            margin-bottom: 20px; } }
        .upload-container .upload-main .solutions-container .solution-type-container .header {
          background-color: #2095a9;
          padding: 15px 10px;
          outline: none;
          color: white;
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600; }
          .upload-container .upload-main .solutions-container .solution-type-container .header.selected {
            background-color: #ffcccb;
            outline: none; }
          .upload-container .upload-main .solutions-container .solution-type-container .header:hover {
            cursor: pointer; }
          .upload-container .upload-main .solutions-container .solution-type-container .header .checkbox {
            margin-right: 5px;
            display: flex;
            align-items: center; }
          @media (max-width: 425px) {
            .upload-container .upload-main .solutions-container .solution-type-container .header {
              font-size: 16px; } }
        .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content {
          flex: 1 1 auto;
          margin-left: 10px;
          margin-right: 10px;
          position: relative; }
          .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .postalCode-search-dropdown-option {
            padding: 5px; }
            .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .postalCode-search-dropdown-option:hover {
              cursor: pointer;
              background-color: #ffcccb;
              color: white;
              font-weight: 600; }
            .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .postalCode-search-dropdown-option:active {
              background-color: #ffcccb; }
            .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .postalCode-search-dropdown-option.selected {
              background-color: #ffcccb; }
          .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .file-picker-container {
            width: 100%;
            display: flex;
            align-items: center; }
            .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .file-picker-container .file-picker {
              flex: 1 1 auto; }
            .upload-container .upload-main .solutions-container .solution-type-container .solution-type-content .file-picker-container .icon-container {
              color: white;
              -webkit-transform: translateY(-8px);
                      transform: translateY(-8px);
              margin-left: 10px; }
    .upload-container .upload-main .order-help-container {
      flex: 1 1;
      margin-bottom: 20px;
      max-width: 450px; }
      @media (max-width: 768px) {
        .upload-container .upload-main .order-help-container {
          flex: 2 1; } }
  .upload-container .hint {
    width: 100%;
    margin-left: 5px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 0.9rem; }
    @media (max-width: 768px) {
      .upload-container .hint {
        font-size: 14px; } }
    @media (max-width: 425px) {
      .upload-container .hint {
        font-size: 12px; } }
  .upload-container .comment-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column; }
  .upload-container .add-file-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .upload-container .add-file-container .text {
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px);
      margin-left: 10px; }
      @media (max-width: 425px) {
        .upload-container .add-file-container .text {
          font-size: 12px; } }
      .upload-container .add-file-container .text:hover {
        cursor: pointer; }
    .upload-container .add-file-container .icon {
      margin-left: 5px; }
      .upload-container .add-file-container .icon:hover {
        cursor: pointer; }

ul {
  margin: 0; }

.order-recap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; }
  .order-recap-container .warning-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px 5px 0px; }
    .order-recap-container .warning-modal-content .title {
      padding: 5px;
      font-size: 18px;
      font-weight: 500; }
    .order-recap-container .warning-modal-content .message {
      padding: 10px;
      text-align: center; }
    .order-recap-container .warning-modal-content .button {
      padding: 10px; }
  .order-recap-container .loading-modal {
    justify-content: center;
    padding: 50px;
    text-align: center; }
    .order-recap-container .loading-modal .title {
      font-size: 1rem;
      margin-bottom: 2em; }

.user-info {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 25px; }
  @media (max-width: 425px) {
    .user-info {
      font-size: 16px; } }
  .user-info .user-name {
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold; }
    @media (max-width: 768px) {
      .user-info .user-name {
        margin-left: 10px;
        margin-right: 10px; } }
  .user-info .user-email {
    margin-left: 20px;
    margin-right: 20px; }
    @media (max-width: 768px) {
      .user-info .user-email {
        margin-left: 10px;
        margin-right: 10px; } }

.order-recap-main {
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px; }
  @media (max-width: 768px) {
    .order-recap-main {
      flex-direction: column; } }
  .order-recap-main .order-items-container {
    flex: 1 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .order-recap-main .order-items-container {
        margin-left: 10px;
        margin-right: 10px; } }
    .order-recap-main .order-items-container .order-item {
      margin-bottom: 5px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-transform: capitalize; }
      .order-recap-main .order-items-container .order-item .order-item-content {
        flex: 1 1 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 20px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        @media (max-width: 425px) {
          .order-recap-main .order-items-container .order-item .order-item-content {
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 20px; } }
        .order-recap-main .order-items-container .order-item .order-item-content .item-wraper {
          flex: 1 1 auto; }
          .order-recap-main .order-items-container .order-item .order-item-content .item-wraper .item-date {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between; }
        .order-recap-main .order-items-container .order-item .order-item-content .icon {
          font-size: 20px;
          -webkit-transform: translateY(2px);
                  transform: translateY(2px); }
          @media (max-width: 425px) {
            .order-recap-main .order-items-container .order-item .order-item-content .icon {
              font-size: 11px; } }
          .order-recap-main .order-items-container .order-item .order-item-content .icon:hover {
            cursor: pointer; }
        .order-recap-main .order-items-container .order-item .order-item-content .text {
          color: #2095a9;
          font-size: 1.2rem;
          font-weight: 600; }
          @media (max-width: 425px) {
            .order-recap-main .order-items-container .order-item .order-item-content .text {
              font-size: 12px; } }
      .order-recap-main .order-items-container .order-item .order-item-missing {
        margin-left: 20px;
        height: 50px;
        display: flex;
        align-items: center; }
      @media (max-width: 425px) {
        .order-recap-main .order-items-container .order-item .button {
          font-size: 10px; } }
  .order-recap-main .order-help-container {
    flex: 1 1;
    margin-bottom: 20px;
    max-width: 450px; }
    @media (max-width: 768px) {
      .order-recap-main .order-help-container {
        flex: 1 1 auto; } }
    .order-recap-main .order-help-container .order-help-order {
      margin-left: 20px;
      margin-right: 20px; }
      @media (max-width: 768px) {
        .order-recap-main .order-help-container .order-help-order {
          margin-left: 10px;
          margin-right: 10px; } }

ul {
  margin: 0; }

.personalInfo-container {
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; }
  @media (max-width: 425px) {
    .personalInfo-container {
      margin-left: 10px;
      margin-right: 10px; } }
  .personalInfo-container .personalInfo-title {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    background: -webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#ebebeb)) bottom no-repeat;
    background: linear-gradient(#ebebeb, #ebebeb) bottom no-repeat;
    background-size: 20% 2px; }
    @media (max-width: 425px) {
      .personalInfo-container .personalInfo-title {
        margin-bottom: 5px; } }
  .personalInfo-container .personalInfo-forms {
    margin-top: 10px;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
    width: 50%; }
    @media (max-width: 768px) {
      .personalInfo-container .personalInfo-forms {
        width: 80%; } }
    @media (max-width: 425px) {
      .personalInfo-container .personalInfo-forms {
        width: 100%; } }
    .personalInfo-container .personalInfo-forms .personalInfo-form {
      margin-left: 20px;
      margin-right: 20px;
      flex: 1 1 auto; }
      @media (max-width: 425px) {
        .personalInfo-container .personalInfo-forms .personalInfo-form {
          margin-left: 10px;
          margin-right: 10px;
          width: 100%; } }
      .personalInfo-container .personalInfo-forms .personalInfo-form .field {
        display: flex;
        align-items: baseline; }
        @media (max-width: 425px) {
          .personalInfo-container .personalInfo-forms .personalInfo-form .field {
            flex-direction: column;
            align-items: stretch; } }
        .personalInfo-container .personalInfo-forms .personalInfo-form .field label {
          flex: 1 1 auto; }
          @media (max-width: 425px) {
            .personalInfo-container .personalInfo-forms .personalInfo-form .field label {
              font-size: 12px;
              margin-bottom: 2px; } }
        .personalInfo-container .personalInfo-forms .personalInfo-form .field .input {
          flex: 2 1; }
          .personalInfo-container .personalInfo-forms .personalInfo-form .field .input .dropdowncontainer {
            position: relative;
            width: 100%; }
            .personalInfo-container .personalInfo-forms .personalInfo-form .field .input .dropdowncontainer .social-reason-option {
              padding: 2px 5px 2px 5px; }
              .personalInfo-container .personalInfo-forms .personalInfo-form .field .input .dropdowncontainer .social-reason-option:hover {
                cursor: pointer;
                color: white;
                background-color: #ffcccb; }
        .personalInfo-container .personalInfo-forms .personalInfo-form .field .buttons {
          flex: 2 1;
          display: flex;
          justify-content: space-between; }
          @media (max-width: 768px) {
            .personalInfo-container .personalInfo-forms .personalInfo-form .field .buttons {
              margin-top: 20px;
              margin-bottom: 20px; } }
          .personalInfo-container .personalInfo-forms .personalInfo-form .field .buttons .placeHolder {
            width: 15px; }
          .personalInfo-container .personalInfo-forms .personalInfo-form .field .buttons .button {
            flex: 1 1 auto; }
    .personalInfo-container .personalInfo-forms .loading-container {
      flex: 1 1 auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }

.form-hidden {
  display: none; }

ul {
  margin: 0; }

.style_container__2M65s:nth-child(odd) {
  background: #f9f9f9; }

.style_container__header__1fF9d {
  display: flex;
  list-style: none;
  padding: 15px 10px;
  justify-content: space-between; }
  @media (max-width: 425px) {
    .style_container__header__1fF9d {
      font-size: 0.8rem;
      flex-wrap: wrap; } }
  .style_container__header__1fF9d li:first-child {
    color: #2095a9;
    font-weight: 600; }
  .style_container__header__1fF9d li:nth-child(2) {
    font-weight: 600; }
  .style_container__header__1fF9d li:last-child {
    text-decoration: underline;
    cursor: pointer; }

.style_container__body__3UYfi {
  padding: 15px 10px; }

ul {
  margin: 0; }

.style_container__noorder__2gwPI {
  margin-bottom: 20px; }

.style_container__item__1ZR5U {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; }
  .style_container__item__1ZR5U:last-of-type {
    width: 100%; }
  @media (max-width: 425px) {
    .style_container__item__1ZR5U {
      width: 100%;
      font-size: 0.8rem;
      margin-bottom: 10px; } }
  .style_container__item__1ZR5U b {
    margin-bottom: 10px; }
  .style_container__item__1ZR5U ul {
    list-style: none;
    padding: 0; }
  .style_container__item__img__1y17i {
    margin-bottom: 20px;
    font-size: 0.8rem;
    padding: 0;
    text-align: left; }
    .style_container__item__img__1y17i img {
      margin-bottom: 10px; }
  .style_container__item__pdf__3bDiu {
    width: 100%;
    min-height: 400px; }

.style_container__buttons__2Kx5u {
  display: flex; }
  .style_container__buttons__2Kx5u button {
    margin-bottom: 20px;
    margin-right: 10px; }

.password-strength-meter {
  text-align: left; }

.password-strength-meter-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 250px;
  height: 8px; }

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px; }

.password-strength-meter-label {
  font-size: 14px; }

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%; }

.strength-Faible::-webkit-progress-value {
  background-color: #F25F5C; }

.strength-Acceptable::-webkit-progress-value {
  background-color: #FFE066; }

.strength-Fort::-webkit-progress-value {
  background-color: #70C1B3; }

ul {
  margin: 0; }

.modifyPassword-container--message {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #2095a9;
  color: white; }

.modifyPassword-container--error {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
  background-color: red;
  color: white; }

.modifyPassword-buttons--row {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  @media (max-width: 425px) {
    .modifyPassword-buttons--row {
      flex-direction: column-reverse; } }
  .modifyPassword-buttons--row .text-button-container {
    margin: 10px; }

.loading-container {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.editpwd-container--message {
  display: block;
  padding: 20px;
  margin: 10px 0;
  background-color: #2095a9;
  color: white; }

span.editpwd-container--message--error {
  background-color: #e93838; }

ul {
  margin: 0; }

.style_container__18gUk {
  display: flex;
  border: 3px solid #f9f9f9; }
  @media (max-width: 768px) {
    .style_container__18gUk {
      flex-direction: column;
      border: none; } }
  .style_container__menu__28YWr {
    flex: 1 1 auto;
    padding: 20px;
    list-style: none;
    border-right: 3px solid #f9f9f9;
    background: #f9f9f9; }
    @media (max-width: 768px) {
      .style_container__menu__28YWr {
        display: flex;
        background: transparent;
        border: none;
        flex-wrap: wrap;
        padding: 10px; } }
    @media (max-width: 425px) {
      .style_container__menu__28YWr {
        padding: 0; } }
    .style_container__menu__28YWr li {
      font-weight: 700;
      color: #2095a9;
      margin-bottom: 10px;
      cursor: pointer; }
      .style_container__menu__28YWr li:hover {
        opacity: 0.8; }
      @media (max-width: 768px) {
        .style_container__menu__28YWr li {
          margin-right: 10px; } }
  .style_container__content__gWp5O {
    flex: 3 3 auto; }
    .style_container__content__title__1bu7X {
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 30px;
      border-bottom: 3px solid #f9f9f9;
      padding: 20px; }
      @media (max-width: 768px) {
        .style_container__content__title__1bu7X {
          padding: 10px; } }
      @media (max-width: 425px) {
        .style_container__content__title__1bu7X {
          padding: 0px;
          padding-top: 10px;
          margin-bottom: 10px;
          border: none; } }
    .style_container__content__info__1gHpm {
      padding: 20px;
      padding-top: 0px; }
      @media (max-width: 768px) {
        .style_container__content__info__1gHpm {
          padding: 0px; } }
    .style_container__content__flash__1VXNO {
      padding: 20px;
      margin: 10px 0;
      background-color: #2095a9;
      color: white; }

.style_modal__content__3Itjm {
  margin: 0 auto;
  width: 80%; }

.style_modal__content__title__2WxJ1 {
  margin: 40px 0; }

ul {
  margin: 0; }

.stripe-input-container {
  margin-bottom: 15px;
  margin-top: 15px; }

.input-container .StripeElement {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #ebebeb;
  font-size: 1rem;
  outline: none;
  border-radius: 0px;
  background: transparent; }

.input-container .StripeElement-err {
  border: none;
  border-bottom: 1px solid #e93838;
  color: #e93838; }

.input-container .text-input-error-placeholder {
  color: red;
  display: flex;
  align-items: flex-start;
  height: 15px; }
  @media (max-width: 425px) {
    .input-container .text-input-error-placeholder {
      height: 4px;
      justify-content: flex-end; } }
  .input-container .text-input-error-placeholder .text-input-error {
    display: flex;
    align-items: flex-start;
    height: 100%;
    font-size: 10px; }
    @media (max-width: 425px) {
      .input-container .text-input-error-placeholder .text-input-error {
        font-size: 8px; } }

ul {
  margin: 0; }

.text-input-container .input-container {
  display: flex;
  flex-direction: column; }
  .text-input-container .input-container input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #ebebeb;
    font-size: 1rem;
    outline: none;
    border-radius: 0px;
    background: transparent; }
    .text-input-container .input-container input.text-input-err {
      border: none;
      border-bottom: 1px solid #e93838;
      color: #e93838; }
      .text-input-container .input-container input.text-input-err::-webkit-input-placeholder {
        color: #e93838; }
      .text-input-container .input-container input.text-input-err::-moz-placeholder {
        color: #e93838; }
      .text-input-container .input-container input.text-input-err:-ms-input-placeholder {
        color: #e93838; }
      .text-input-container .input-container input.text-input-err::-ms-input-placeholder {
        color: #e93838; }
      .text-input-container .input-container input.text-input-err::placeholder {
        color: #e93838; }
      .text-input-container .input-container input.text-input-err::-ms-input-placeholder {
        color: #e93838; }
    .text-input-container .input-container input:focus {
      outline: none; }
  .text-input-container .input-container .text-input-error-placeholder {
    color: red;
    display: flex;
    align-items: flex-start;
    height: 15px; }
    @media (max-width: 425px) {
      .text-input-container .input-container .text-input-error-placeholder {
        height: 4px;
        justify-content: flex-end; } }
    .text-input-container .input-container .text-input-error-placeholder .text-input-error {
      display: flex;
      align-items: flex-start;
      height: 100%;
      font-size: 10px; }
      @media (max-width: 425px) {
        .text-input-container .input-container .text-input-error-placeholder .text-input-error {
          font-size: 8px; } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

ul {
  margin: 0; }

#root {
  min-height: 100vh; }
  #root .page-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; }
  #root .App {
    height: 100%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      #root .App #header {
        position: fixed;
        top: 0px;
        z-index: 1;
        height: 50px;
        width: 100%; } }
    #root .App #content {
      flex: 1 1 auto;
      min-height: 1px; }
      @media (max-width: 425px) {
        #root .App #content {
          margin-top: 50px; } }
    #root .App #footer {
      flex-shrink: 0; }

ul {
  margin: 0; }

@-ms-viewport {
   {
    width: device-width; } }

@viewport {
   {
    width: device-width; } }

html, body {
  min-height: 100vh; }

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  position: relative;
  margin: 0;
  counter-reset: heading-counter;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-family: "Montserrat", sans-serif;
  color: #0b0b0b; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none;
  color: #0b0b0b; }

button {
  background: transparent;
  outline: none;
  border: none;
  text-transform: uppercase; }

