@import "~styles/fonts.scss";

$white: #ffffff;
$light-grey: #f9f9f9;
$company-gray: #ebebeb;
$company-gray-dark: #bbbbbb;
$gray-medium: #aaaaaa;
$gray-dark: #606060;
$black-light: rgb(85, 85, 85);
$black: #0b0b0b;
$blue: #2095a9;
$dark-blue: #0b353d;
$darker-blue: #072428;
$pink: #ffcccb;
$red-dark: rgb(233, 56, 56);

$small-desktop-size: 1024px;
$tablet-size: 768px;
$phone-size: 425px;
$header-height: 80px;
$mobile-header-height: 50px;

$font-family: 'Montserrat', sans-serif;
$regular: 400;
$semi-bold: 600;
$bold: 700;