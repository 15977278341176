@import "~styles/config.scss";

.container{
    padding: 30px;
    &__wrapper{
        @media (min-width: $tablet-size+1) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
          }
        h4{
            margin: 0;
        }
        p{
            font-size: 0.9rem;
        }
        ul{
            list-style: none;
            padding: 0;
            font-size: 0.9rem;
            li{
                margin-top: 10px;
            }
        }
    }
    p{
        font-size: 0.9rem;
        margin: 0;
        margin-top: 20px;
    }
    span{
        font-size: 0.8rem;
        color: $gray-dark;
    }
}